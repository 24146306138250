import React, { useState } from "react";
import EssayNew from "./essayNew";
import MatchNew from "./MatchNew";
import MultiChoiceNew from "./multiChoiceNew";
import ShortAnswerNew from "./ShortAnswerNew";
import TrueFalseNew from "./TrueFalseNew";
import { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { CardMedia, Dialog, DialogContent, } from "@mui/material";
import { alphabets, romanNumbers } from "../../../../utils/AppConstants";

const Text = ({
  selectedAnswer,
  setSelectedAnswer,
  question,
  show,
  count,
  secondLevelIndex,
  attemptidd,
  thirdLevelIndex,
  setthirdLevelIndex,
  handleChange,
  level,
  type,
  editorData,
  setEditorData,quizStatus,quiz, clearResponse,
  setClearResponse
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialog(true);
    setImageSrc(src);
  };
  return (
    <div>
      <Grid container sx={{}}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.5} md={7.5}>
          <Typography
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "14px",
              lineHeight: "140%",
              paddingTop: type === "text" ? "0px" : "10px",
            }}
          >
            {" "}
            {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : `${alphabets[secondLevelIndex]})`}
          </Typography>{" "}
          <Typography
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: type === "text" ? "0px" : "20px",
            }}
          >
            {parse(question?.submqtext, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                  src={domNode.attribs.src}
                  style={{
                    maxWidth: '65%', // Ensures the image fits within the parent container
                    maxHeight: '65%', // Ensures the image fits within the parent container
                    objectFit: 'contain', // Maintains the aspect ratio of the image
                    cursor: 'pointer'
                  }}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
          </Typography>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
          <Grid item xs={1.5} md={1.5} />
          <Grid item xs={5.2} md={7.3}></Grid>
        </Grid>
      </Grid>

      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "text" ? (
        <Text
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          handleChange={handleChange}
          count={count}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "multichoice" ? (
        <MultiChoiceNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          handleChange={handleChange}
          count={count}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          level={level}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "truefalse" ? (
        <TrueFalseNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          thirdLevelIndex={thirdLevelIndex}
          show={true}
          type="text"
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "match" ? (
        <MatchNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          secondLevelIndex={secondLevelIndex}
          count={count}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
          questionStatusResponse={questionStatusResponse}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}
        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "shortanswer" ? (
        <ShortAnswerNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          secondLevelIndex={secondLevelIndex}
          count={count}
          level={level}
          handleChange={handleChange}
          type="text"
          thirdLevelIndex={thirdLevelIndex}
          quiz={quiz}
          quizStatus={quizStatus}
          clearResponse={clearResponse}
          setClearResponse={setClearResponse}

        />
      ) : (
        ""
      )}
      {question.children && question?.children[thirdLevelIndex]?.submqqtype === "essay" ? (
        <EssayNew
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          question={question?.children[thirdLevelIndex]}
          show={true}
          count={count}
          secondLevelIndex={secondLevelIndex}
          attemptidd={attemptidd}
          handleChange={handleChange}
          level={level}
          thirdLevelIndex={thirdLevelIndex}
          setthirdLevelIndex={setthirdLevelIndex}
          type="text"
          editorData={editorData}
          setEditorData={setEditorData}
          quiz={quiz}
          quizStatus={quizStatus}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default Text;
