/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class MatchNew.jsx
 * This JSX file is for showing the Match Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 03 Feb 2023
 */import React, { useState, useEffect } from "react";

 import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, CardMedia, Dialog, DialogContent, Grid, Typography} from '@mui/material';
 import parse from "html-react-parser";
 import "./style.css";
 import { useSelector } from "react-redux";
 import { makeStyles } from "@mui/styles";
 import { romanNumbers } from "../../../../utils/AppConstants";
 
 const USE_STYLES = makeStyles(() => ({
   divContainer: {
     borderTop: "2px dashed #634699",
     height: "25px",
   },
 }));
 function MatchNew({ 
   question,
   thirdLevelIndex,
   selectedAnswer,
   setSelectedAnswer,
   handleChange,
   count,
   secondLevelIndex,
   show, clearResponse,
   setClearResponse,
   type,level }) {
     const questionId = question?.questionid || question?.submqid;
     const questions = question?.matchoptions?.columna;
     const options = question?.matchoptions?.columnb;
   const [openDialog, setOpenDialog] = useState(false);
   const [imageSrc, setImageSrc] = useState("");
 
   const handleImageClick = (src) => {
     setOpenDialog(true);
     setImageSrc(src);
   };
 
   const CLASSES = USE_STYLES();
     const localStorageKeyPrefix = `selectedAnswers_${questionId}`;
     const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
     const [selectedQuestion, setSelectedQuestion] = useState(null);
     const [selectedAnswers, setSelectedAnswers] = useState(() => {
         const storedAnswers = JSON.parse(localStorage.getItem(`${localStorageKeyPrefix}_selectedAnswers`));
         return storedAnswers || Array(questions.length).fill({ value: '', index: null });
     });
     const [disabledOptions, setDisabledOptions] = useState(() => {
         const storedDisabledOptions = JSON.parse(localStorage.getItem(`${localStorageKeyPrefix}_disabledOptions`));
         return storedDisabledOptions || Array(options.length).fill(false);
     });
     useEffect(() => {
      if (clearResponse) {
        // Clear selected answers and reset disabled options
        setSelectedAnswers(Array(questions.length).fill({ value: '', index: null }));
        setDisabledOptions(Array(options.length).fill(false));
        localStorage.removeItem(`${localStorageKeyPrefix}_selectedAnswers`);
        localStorage.removeItem(`${localStorageKeyPrefix}_disabledOptions`);
  
        // Update the question status response
        updateQuestionStatusResponse(count, secondLevelIndex, thirdLevelIndex, Array(questions.length).fill({ value: '', index: null }));
  
        // Reset clearResponse state
        setClearResponse(false);
      }
    }, [clearResponse, questionId, questions.length, options.length, count, secondLevelIndex, thirdLevelIndex, setClearResponse]);
  
    const getLocalStorageKey = () => {
    return `selectedAnswers_${questionId}_${level}_${secondLevelIndex}_${thirdLevelIndex}`;
  };

  const getDisabledOptionsLocalStorageKey = () => {
    return `disabledOptions_${questionId}_${level}_${secondLevelIndex}_${thirdLevelIndex}`;
  };

     useEffect(() => {
    if (show) {
      const storedAnswers = localStorage.getItem(getLocalStorageKey());
      if (storedAnswers) {
        setSelectedAnswers(JSON.parse(storedAnswers));
      } else {
        setSelectedAnswers(Array(questions.length).fill({ value: '', index: null }));
      }
      const storedDisabledOptions = localStorage.getItem(getDisabledOptionsLocalStorageKey());
      if (storedDisabledOptions) {
        setDisabledOptions(JSON.parse(storedDisabledOptions));
      } else {
        setDisabledOptions(Array(options.length).fill(false));
      }
    }
  }, [questionId, level, secondLevelIndex, thirdLevelIndex, show]);
   
   useEffect(() => {
     if (!show) {
         localStorage.removeItem(getLocalStorageKey());
         localStorage.removeItem(getDisabledOptionsLocalStorageKey());
         setSelectedAnswers(Array(questions.length).fill({ value: '', index: null }));
         setDisabledOptions(Array(options.length).fill(false));
    }
  }, [show, questionId, level, secondLevelIndex, thirdLevelIndex]);
 
     const handleQuestionSelect = (questionIndex) => {
         setSelectedQuestion(questionIndex);
     };
 
     const handleAnswerSelect = (optionIndex) => {
         if (selectedQuestion !== null && !disabledOptions[optionIndex]) {
             setSelectedAnswers((prevAnswers) => {
                 const newAnswers = [...prevAnswers];
                 newAnswers[selectedQuestion] = { value: options[optionIndex].value, index: options[optionIndex].index };
                 localStorage.setItem(getLocalStorageKey(), JSON.stringify(newAnswers));
                 updateQuestionStatusResponse(count, secondLevelIndex, thirdLevelIndex, newAnswers);
                 return newAnswers;
             });
             setDisabledOptions((prevDisabled) => {
                 const newDisabled = [...prevDisabled];
                 newDisabled[optionIndex] = true;
                 localStorage.setItem(getDisabledOptionsLocalStorageKey(), JSON.stringify(newDisabled));
                 return newDisabled;
             });
         }
     };
 
    const updateQuestionStatusResponse = (count, secondLevelIndex, thirdLevelIndex, selectedAnswers) => {
     if (questionStatusResponse && questionStatusResponse[count]) {
         const updatedList = selectedAnswers.map(answer => answer);
         if (questionStatusResponse[count].type === "multiquestion") {
             if (secondLevelIndex !== undefined) {
                 if (thirdLevelIndex !== undefined) {
                     // If thirdLevelIndex is defined, update the third level
                     questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer = updatedList;
                     questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].status = "Answered";
                 } else {
                     // Otherwise, update the second level
                     questionStatusResponse[count].subQuestions[secondLevelIndex].answer = updatedList;
                     questionStatusResponse[count].subQuestions[secondLevelIndex].status = "Answered";
                 }
             } else {
                 // If secondLevelIndex is undefined, update the first level
                 questionStatusResponse[count].answer = updatedList;
                 questionStatusResponse[count].status = "Answered";
             }
         } else {
             questionStatusResponse[count].answer = updatedList;
             // questionStatusResponse[count].status = "Answered";
         }
     }
     };
 
     const handleRemoveAnswer = (index) => {
         setSelectedAnswers((prevAnswers) => {
             const newAnswers = [...prevAnswers];
             newAnswers[index] = { value: '', index: null };
             localStorage.setItem(getLocalStorageKey(), JSON.stringify(newAnswers));
             updateQuestionStatusResponse(count, secondLevelIndex, thirdLevelIndex, newAnswers);
             return newAnswers;
         });
         // Re-enable the corresponding option button
         const newDisabledOptions = [...disabledOptions];
         newDisabledOptions.forEach((_, i) => {
             if (selectedAnswers[index].value === options[i].value) {
                 newDisabledOptions[i] = false;
             }
         });
         setDisabledOptions(newDisabledOptions);
     };
 
   
   const formatter = new Intl.NumberFormat("en-US", {
     minimumFractionDigits: 2,
     maximumFractionDigits: 2,
   });
   return (
     <Grid container sx={{ marginTop: "1%" }}>
       <Grid item xs={1.5} md={1.5} />
         <Grid item xs={5.2} md={7.3}>
         {/* <Typography
           variant="h2"
           sx={{
             fontWeight: 700,
             fontStyle: "regular",
             color: "#634699",
             fontSize: "16px",
             lineHeight: "140%",
             paddingBottom: "10px",
           }}
         >
           {count + 1}.
         </Typography> */}
         {/* <Typography
           variant="h2"
           sx={{
             fontWeight: 400,
             fontStyle: "regular",
             color: "#000000",
             fontSize: "16px",
             lineHeight: "150%",
             paddingBottom: "15px",
           }}
         >
           Choose the correct Answer:
         </Typography> */}
         <Typography
           variant="h2"
           sx={{
             fontWeight: 700,
             fontStyle: "regular",
             color: "#634699",
             fontSize: "16px",
             lineHeight: "140%",
             paddingBottom: "10px",
           }}
         >
           {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
         </Typography>{" "}
         <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
         <Typography
           variant="h2"
           sx={{
             fontWeight: 400,
             fontStyle: "regular",
             color: "#000000",
             fontSize: "16px",
             lineHeight: "150%",
             paddingBottom: "30px",
           }}
         >
           {parse(question?.submqtext || question?.questiontext || question.question, {
           replace: (domNode) => {
             if (domNode.type === "tag" && domNode.name === "img") {
               // If image tag found, add click event to open dialog with larger image
               return (
                 <img
                   src={domNode.attribs.src}
                   style={{
                    maxWidth: '65%', // Ensures the image fits within the parent container
                    maxHeight: '65%', // Ensures the image fits within the parent container
                    objectFit: 'contain', // Maintains the aspect ratio of the image
                    cursor: 'pointer'
                  }}
                   alt=""
                   onClick={() => handleImageClick(domNode.attribs.src)}
                 />
               );
             }
           },
         })}
         </Typography>
         <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
         <DialogContent>
           <CardMedia component="img" image={imageSrc} />
         </DialogContent>
       </Dialog>
         {show && question?.defaultmark !== null ? (
   <Typography
     variant="h2"
     sx={{
       fontWeight: 700,
       fontStyle: "regular",
       color: "#634699",
       fontSize: "14px",
       lineHeight: "150%",
       lineHeight: "150%",
       paddingBottom: "30px",
     }}
   >
     ({question?.defaultmark||question?.submqmark ? formatter.format(question.defaultmark||question?.submqmark) : ""} Mark)
   </Typography>) : null}
 </div>
         <Grid item container className={CLASSES.divContainer} />
         {!show ? (
           ""
         ) : (
           <Typography
             variant="h2"
             sx={{
               fontWeight: 700,
               fontStyle: "regular",
               color: "#634699",
               fontSize: "16px",
               lineHeight: "140%",
               paddingBottom: "10px",
             }}
           >
             Answer
           </Typography>
         )}
         {!show ? (
           ""
         ) : (
           <Typography
             variant="h2"
             sx={{
               fontWeight: 700,
               fontStyle: "regular",
               color: "#000000",
               fontSize: "14px",
               lineHeight: "140%",
               paddingBottom: "15px",
             }}
           >
             Arrange&nbsp;
             <span
               style={{
                 fontWeight: 400,
                 fontStyle: "regular",
                 color: "#000000",
                 fontSize: "14px",
                 lineHeight: "140%",
                 paddingBottom: "15px",
               }}
             >
               the appropriate objects (In column B ) to the respective statements (in Column A )
             </span>
           </Typography>
         )}
         <br />
         <Grid item xs={12}>
           <Grid
             container
             sx={{
               width: question?.submqtext || question?.questiontext ? "100%" : "170%",
             }}
           >
               <div>
             <div>
                 <h2 style={{ textAlign: "center" }}>Options</h2>
                 <div style={{ display: 'flex', justifyContent: 'center' }}>
                     {options.map((option, index) => (
                         <Button
                             key={index}
                             variant="outlined"
                             style={{
                                 marginRight: '10px',
                                 marginBottom: '10px',
                                 backgroundColor:
                                     selectedQuestion !== null && selectedAnswers[selectedQuestion].index === index
                                         ? 'lightblue'
                                         : 'transparent',
                             }}
                             onClick={() => handleAnswerSelect(index)}
                             disabled={disabledOptions[index]||!show}
                         >
                             {option.value||option}
                         </Button>
                     ))}
                 </div>
             </div>
             <div>
                 <div style={{ display: 'flex', justifyContent: 'center' }}>
                     <TableContainer component={Paper}>
                         <Table>
                             <TableHead>
                                 <TableRow>
                                     <TableCell>Column A</TableCell>
                                     <TableCell>Column B</TableCell>
                                 </TableRow>
                             </TableHead>
                             <TableBody>
                             {questions.map((question, index) => (
     <TableRow
         key={index}
         style={{ backgroundColor: !show?"":selectedQuestion === index ? 'lightblue' : 'transparent' }}
         onClick={() => handleQuestionSelect(index)}
         disabled={!show}
     >
         <TableCell>
             {/* Check if the question includes an image */}
             {question.includes("<img") ? (
                 // If it includes an image, extract the image source
                 <>
                     {/* Remove the image tags and get the text */}
                     {parse(question.replace(/<img(.*?)\/?>/g, ""))}
                     {/* Extract the image source */}
                     {question.match(/src="(.*?)"/) && (
                         <img src={question.match(/src="(.*?)"/)[1]} alt="Question Image" style={{ maxWidth: '100px' }} />
                     )}
                 </>
             ) : (
                 // If no image, simply render the text
                 parse(question)
             )}
         </TableCell> <TableCell>
                                             {selectedAnswers[index]?.value}
                                             {selectedAnswers[index]?.value && (
                                                 <Button size="small" disabled={!show} onClick={() => handleRemoveAnswer(index)}>
                                                     x
                                                 </Button>
                                             )}
                                         </TableCell>
                                     </TableRow>
                                 ))}
                             </TableBody>
                         </Table>
                     </TableContainer>
                 </div>
             </div>
         </div>
             <Grid item xs={8} />
           </Grid>
         </Grid>
       </Grid>
     </Grid>
       
     );
 }
 
 export default MatchNew;
 