import { Alert, Button, Card, Dialog, FormHelperText, Grid, ImageList, ImageListItem, Snackbar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { getBase64 } from "../../../../utils/AppConstants";
// import FileThumbnail from "./FileThumbnail";
import InsertFileIcon from "../../../../assets/images/InsertFileIcon.png";
import { useDispatch, useSelector } from "react-redux";
import FileThumbnailNew from "./FileThumbnailNew";
import { uploadedFiles } from "../../../../webservice/students/actions/TestAttemptAction";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import Fileuploadvalidatescreen from "./Fileuploadvalidatescreen";
import { useEffect } from "react";
let maxMb = 8;
const USE_STYLES = makeStyles(() => ({
  testContainer: {
    padding: "30px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  divContainer: {
    border: "3px dotted #634699",
    borderRadius: "4px",
    height: "150px",
    padding: "10px",
  },
  image: {
    width: "11.12vw",
    height: "auto",
    marginTop: "4%",
    position: "relative",
    cursor: "pointer",
  },
  thumbnailContainer: {
    position: "relative",
  },
}));
const FileUploadPreviewNew = ({
  onClose,
  onUpload,
  fileSizeLimit,
  attemptidd,
  count,
  secondLevelIndex,
  level,
  thirdLevelIndex,
}) => {
  const dispatch = useDispatch();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);

  const CLASSES = USE_STYLES();
  const [selectedImage, setSelectedImage] = useState([]);
  const { uploadfileList } = useSelector((state) => state.testAttemptState);
  const [imageUrl, setImageUrl] = useState(uploadfileList);
  const [previewImage, setPreviewImage] = useState("");
  const [isUpload, setIsUpload] = useState(true);
  useEffect(() => {
    if (level === 2) {
      if (questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.uploadedFiles.length !== 0) {
        setIsUpload(false);
      } else {
        setIsUpload(true);
      }
    } else if (level === 1) {
      if (questionStatusResponse[count]?.uploadedFiles.length !== 0) {
        setIsUpload(false);
      } else {
        setIsUpload(true);
      }
    } else if (level === 3) {
      if (
        questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.uploadedFiles.length !== 0
      ) {
        setIsUpload(false);
      } else {
        setIsUpload(true);
      }
    }
  }, []);
  const [snackbar, setSnackbar] = useState({
    open: false,
    severity: "warning",
    message: "",
  });
  const tobase64Handler = async (files) => {
    const filePathsPromises = [];

    files.forEach((file) => {
      filePathsPromises.push(getBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    const mappedFiles = filePaths.map((base64File, index) => ({
      filename: files[index].name,
      filetype: files[index].type,
      basedata: base64File,
    }));
    return mappedFiles;
  };

  const handleUpload = () => {
    if (selectedImage !== null) {
      if (selectedImage.length > 0) {
        let filesLength = selectedImage.length;

        tobase64Handler(selectedImage).then((filesArray) => {
          onUpload(filesArray, filesLength);
          setSelectedImage([]);
          onClose();
        });
      } else {
        setSnackbar({
          ...snackbar,
          open: true,
          severity: "warning",
          message: "Please select file(s) to upload!",
        });
      }
    }
  };
  const processFile = async (e) => {
    setIsUpload(false);
    try {
      let files = [...e?.target?.files];
      files.forEach((file, index) => {

        let size = file.size / 1024;
        let mb = size / 1024;
        const extn = file.name.split('.').pop().toLowerCase(); // Get the file extension
        const allowedTypes = ["png", "jpeg", "jpg", "pdf", "docx","doc", "pptx","ppt", "heic"];
        if (!allowedTypes.includes(extn)) {
          setSnackbar({
            ...snackbar,
            open: true,
            severity: "error",
            message: `Please Upload Jpeg, PNG, PDF, Docx, DOC, PPTX, PPT and HEIC format files`,
          });
          files.splice(index, 1);
        }
        if (mb > maxMb) {
          setSnackbar({
            ...snackbar,
            open: true,
            severity: "error",
            message: `${file.name}: File size limit exceeds!`,
          });
          files.splice(index, 1);
        }
      });
      if (
        (files[0].type === "image/png" ||
          files[0].type === "image/jpeg" ||
          files[0].type === "application/pdf" ||
          files[0].type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
          files[0].type === "application/msword" ||
          files[0].type === "application/vnd.ms-powerpoint" ||
          files[0].type === "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
          files[0].type === ""
        ) &&
        files[0].size > maxMb
      ) {
        if (level === 2) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles.push(files[0]);
        } else if (level === 1) {
          questionStatusResponse[count].uploadedFiles.push(files[0]);
        } else if (level === 3) {
          questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles.push(files[0]);
        }
      }
      dispatch(fetchQuestionStatus(questionStatusResponse));
      setSelectedImage([...selectedImage, ...files]);
    } catch (error) {
      return error;
    }
  };
  const handleThumbnailDelete = (index) => {
    let array = [];
    if (level === 1) {
      array = [...questionStatusResponse[count].uploadedFiles];
      array.splice(index, 1);
      questionStatusResponse[count].uploadedFiles = array;
    } else if (level === 2) {
      array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles];
      array.splice(index, 1);
      questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles = array;
    } else if (level === 3) {
      array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles];
      array.splice(index, 1);
      questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles = array;
    }

    dispatch(fetchQuestionStatus(questionStatusResponse));
    setSelectedImage(array);
  };
  const onCloseHandler = () => {
    setSnackbar({ ...snackbar, open: false, message: "" });
  };
  return (
    <div>
      <Grid container item xs={12}>
        <Card className={CLASSES.testContainer}>
          <Grid container item xs={12} spacing={1} sx={{ padding: "30px 30px 20px 30px" }}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 700,
                  fontSize: "20px",
                  lineHeight: "140%",
                  textAlign: "center",
                }}
              >
                Upload Your Images
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: "#634699",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "140%",
                  textAlign: "center",
                }}
              >
                PNG, JPG, PDF’S, DOCX, DOC, PPTX, PPT and HEIC Files are Allowed
              </Typography>
            </Grid>
          </Grid>
          <div style={{ padding: "0px 8px 20px 8px" }}>
            <Grid item container className={CLASSES.divContainer}>
              {" "}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontStyle: "regular",
                    color: "#634699",
                    fontSize: "1.5vw",
                    textAlign: "center",
                  }}
                >
                  <img src={InsertFileIcon} alt="preview" style={{ width: "30px", height: "30px" }} />
                </Typography>
              </Grid>{" "}
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    color: "#634699",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Drag and Drop or Browse to Choose a file
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid item container xs={12}>
            <Grid item xs={12}>
              <FileThumbnailNew
                classes={CLASSES}
                selectedImage={selectedImage}
                setPreviewImage={setPreviewImage}
                handleDelete={handleThumbnailDelete}
                processFile={processFile}
                count={count}
                secondLevelIndex={secondLevelIndex}
                level={level}
                thirdLevelIndex={thirdLevelIndex}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6.8}>
              {" "}
            </Grid>
            <Grid item xs={2.6}>
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #634699",
                  color: "#634699",
                  padding: "5px 30px",
                  boxShadow: "none",
                  "&:hover": { backgroundColor: "#ffffff", boxShadow: "none" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    color: "#634699",
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  {" "}
                  Cancel
                </Typography>
              </Button>
            </Grid>{" "}
            {!isUpload ? (
              <Button
                onClick={() => {
                  handleUpload();
                }}
                variant="contained"
                color="primary"
                component="div"
                disabled={selectedImage.length === 0}
                sx={{
                  padding: "5px 30px",
                  textTransform: "none",
                  backgroundColor: "#634699",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#634699",
                    boxShadow: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    color: "#FFFFFF",
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  Submit
                </Typography>
              </Button>
            ) : (
              <Grid item xs={2}>
                <input type="file" id="select-image" style={{ display: "none" }} multiple onChange={processFile} />
                <label htmlFor="select-image">
                  <Button
                    variant="contained"
                    color="primary"
                    component="div"
                    sx={{
                      padding: "5px 30px",
                      textTransform: "none",
                      backgroundColor: "#634699",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#634699",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        lineHeight: "150%",
                      }}
                    >
                      Browse
                    </Typography>
                  </Button>
                  {/* {fileSizeLimit?.name && ( */}
                    <FormHelperText error sx={{paddingLeft:"20px"}}>
                      (Max. 8MB)
                    </FormHelperText>
                  {/* )} */}
                </label>
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
      <Dialog
        PaperProps={{
          style: {
            maxHeight: "none",
          },
        }}
        open={snackbar.open}
        sx={{
          backgroundColor: "rgba(99, 70, 153,  60%)",
        }}
      >
        <Fileuploadvalidatescreen onClose={onCloseHandler} message={snackbar.message} />
      </Dialog>
    </div>
  );
};

export default FileUploadPreviewNew;
