/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class schedule.js
 * This JS file is for showing the list of scheduled meetings with the tutor.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 june 2022
 */

import * as React from "react";
import ScheduleIcon from "../../../assets/images/ScheduleIcon.png";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { makeStyles } from "@mui/styles";
import { Scrollbars } from "react-custom-scrollbars-2";
import parse from "html-react-parser";
import IMG from "../../../views/main/ui_component/img";
import {
  Card,
  Typography,
  Grid,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Link,
  Box,
  DialogActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { deleteMeeting, fetchScheduleList } from "../../../webservice/students/actions";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import { fetchEnrolledTests } from "../../../webservice/students/actions/MyTestPackageAction";
import moment from "moment-timezone";

const USE_STYLES = makeStyles({
  card: {
    width: "102%",
    height: "210px",
    margin: "auto",
    borderRadius: "5px",
    transition: "0.3s",
  },
  avatar: {
    borderRadius: "5px",
  },
});

const SCHEDULE = () => {
  const classes = USE_STYLES();
  const HISTORY = useHistory();
  const dispatch = useDispatch();
  const { deletingMeeting } = useSelector((state) => state.dashboardState);
  const { scheduleList } = useSelector((state) => state.dashboardState);
  const [count, setCount] = React.useState(false);
  const { userid } = useSelector((state) => state.authState);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  const [open, setOpen] = React.useState(false);
  const [fullListOpen, setFullListOpen] = React.useState(false);
  const [name, setName] = React.useState();
  const [description, setDescription] = React.useState();
  const [tutorDescription, setTutorDescription] = React.useState();
  const [id, setId] = React.useState();
  const [time, setTime] = React.useState();

  const handleDelete = (eventId) => {
    dispatch(deleteMeeting(eventId));
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (content) => {
    setName(content.name);
    setDescription(content.description);
    setTutorDescription(content.tutordescription);
    setId(content.id);
    setTime(content.timestart);
    setOpen(false);
  };

  const handleFullListOpen = () => {
    setFullListOpen(true);
  };

  const handleFullListClose = () => {
    setFullListOpen(false);
  };

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  useEffect(() => {
    dispatch(fetchScheduleList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (testList?.length > 0) {
      setCount(false);
    } else {
      setCount(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testList]);

  const generateStatus = (timestamp) => {
    if (timestamp) {
      let eventTimestamp = timestamp * 1000;

      let currDateTime = new Date();
      let currTimestamp = currDateTime.getTime();

      if (eventTimestamp + 1800 > currTimestamp)
        return <span style={{ color: "green" }}>Active</span>;
      else return <span style={{ color: "red" }}>Inactive</span>;
    }
  };
  const melbourneTime = moment.unix(time).tz('Australia/Melbourne');
  const formattedDate = melbourneTime.format('MM/DD/YYYY');
  const formattedTime = melbourneTime.format('h:mm a');
  const timezone = melbourneTime.format('z');
  const statusElement = generateStatus(time);
  const isInactive = statusElement?.props?.children === "Inactive";

  
  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid item xs={12} >
              <Grid container spacing={7}>
                <Grid item xs={6} md={6}>
                  <Typography
                    style={{
                      color: "#634699",
                      fontWeight: 700,
                      fontSize: "18px",
                      lineHeight: "25.2px",
                      paddingLeft: "21px",
                      paddingTop: "8px",
                    }}
                  >
                    Schedule
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6} >
                  <Button
                    disabled={count}
                    onClick={() => HISTORY.push("/schedule")}
                    variant="contained"
                    sx={{
                      backgroundColor: "#634699",
                      textTransform: "none",
                      fontSize: "11px",
                      // lineHeight: "39.2px",
                      padding: "5px 8px",
                      margin:"15px 20px",
                      "&:hover": { backgroundColor: "#634699" },
                    }}
                  >
                    Schedule Now
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* <Scrollbars style={{ height: 80 }}> */}
              {scheduleList && scheduleList.length ? (
                [...scheduleList].reverse().slice(0, 2)?.map((schedule, index) => (
                  <React.Fragment key={schedule.id}>
                    <List
                      key={schedule.id}
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        textDecoration: "none",
                        bgcolor: "background.paper",
                      }}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar
                           sx={{ width: 30, height: 30 }}
                            className={classes.avatar}
                            alt="Task Icon"
                            src={ScheduleIcon}
                          ></Avatar>
                        </ListItemAvatar>
                        <Tooltip title={schedule.name} placement="bottom-end">
                          <Link
                            style={{ textDecoration: "none" }}
                            component="button"
                            variant="body2"
                            onClick={() => {
                              handleClick(schedule);
                              setOpen(!open);
                            }}
                          >
                            <ListItemText
                              primary={
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    lineHeight: "19.6px",
                                    textAlign: "left",
                                  }}
                                >
                                  {schedule.name}
                                </Typography>
                              }
                            />
                          </Link>
                        </Tooltip>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>

                    <Dialog
                      open={open}
                      // onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle
                        id="alert-dialog-title"
                        sx={{ backgroundColor: "#634699", color: "#fff" }}
                      >
                        {name}
                      </DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2} direction="column" p={2}>
                          <Grid item container xs={12} direction="row">
                            <Grid item xs={1}>
                              <AccessTimeOutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                              <DialogContentText id="alert-dialog-description">
                                {/* {new Date(time * 1000).toLocaleDateString()},{" "}
                                {new Date(time * 1000).toLocaleTimeString()} to{" "}
                                {new Date(
                                  (time + 30 * 60) * 1000
                                ).toLocaleTimeString()} */}
                                {formattedDate},&nbsp;&nbsp;{formattedTime} - &nbsp;({timezone}) 
                              </DialogContentText>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={1}>
                              <DescriptionOutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                              <DialogContentText id="alert-dialog-description">
                                {description
                                  ? description.replace(/<[^>]+>/g, "")
                                  : null}
                              </DialogContentText>
                            
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={1}>
                              <StickyNote2OutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                             
                              <DialogContentText id="alert-dialog-description">
          {/* Display the tutor description */}
          {tutorDescription ? tutorDescription.replace(/<[^>]+>/g, "") : "No Tutor Description"}
        </DialogContentText>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Grid item xs={1} display="flex">
                              <EventOutlinedIcon />
                            </Grid>
                            <Grid item xs={1} />
                            <Grid item xs={10}>
                              <DialogContentText id="alert-dialog-description">
                                {generateStatus(time)}
                              </DialogContentText>
                            </Grid>
                          </Grid>


                        </Grid>
                        <Grid item container xs={12} sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        {/* <Button onClick={handleCloseDialog} color="primary"> */}
        <Button
                variant="contained"
                color="primary"
                onClick={handleClose}
                
                sx={{
                  textTransform: "none",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #634699",
                  color: "#634699",
                  padding: "5px 30px",
                  boxShadow: "none",
                  "&:hover": { backgroundColor: "#ffffff", boxShadow: "none" },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    color: "#634699",
                    fontSize: "14px",
                    lineHeight: "150%",
                  }}
                >
                  
          Close</Typography>
        </Button>
       
          <Button
            variant="contained"
            color="primary"
            component="div"
            onClick={() => handleDelete(id)}
            disabled={isInactive}
            sx={{
              padding: "5px 30px",
              textTransform: "none",
              backgroundColor: "#634699",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#634699",
                boxShadow: "none",
              },
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontStyle: "regular",
                color: "#FFFFFF",
                fontSize: "14px",
                lineHeight: "150%",
              }}
            >
              Delete
            </Typography>
          </Button>
         
        
        </Grid>
                      </DialogContent>
                    </Dialog>
                  </React.Fragment>
                ))
              ) : (
                <Box sx={{ lineHeight: "19.6px", textAlign: "center" }}>
                  <IMG width="100px" />
                  <Typography
                    sx={{
                      color: "#718096",
                      fontWeight: 700,
                      fontSize: "14px",
                      lineHeight: "19.6px",
                      textAlign: "center",
                    }}
                  >
                    No Schedule
                  </Typography>
                  <br />
                </Box>
              )}
            {/* </Scrollbars> */}
            {scheduleList && scheduleList.length > 2 && (
              <Grid container justifyContent="center">
                <Link
                  onClick={handleFullListOpen}
                  variant="contained"
                  sx={{
                    cursor:"pointer",
                    textDecoration:"none",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    fontSize: "12px",
                    color: "#634699",
                    fontWeight: 600,
                    // "&:hover": { backgroundColor: "#634699", color: "white" },
                  }}
                >
                  See More
                </Link>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>

      <Dialog
        open={fullListOpen}
        onClose={handleFullListClose}
        aria-labelledby="full-list-dialog-title"
        aria-describedby="full-list-dialog-description"
      >
        <DialogTitle
          id="full-list-dialog-title"
          sx={{ backgroundColor: "#634699", color: "#fff", textAlign:"center" }}
        >
          Schedule 
        </DialogTitle>
        <DialogContent>
        {Array.isArray(scheduleList) && scheduleList.length ? (
  [...scheduleList].reverse().map((schedule, index) => (
            <React.Fragment key={schedule.id}>
              <List
                key={schedule.id}
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  textDecoration: "none",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      className={classes.avatar}
                      alt="Task Icon"
                      src={ScheduleIcon}
                    ></Avatar>
                  </ListItemAvatar>
                  <Tooltip title={schedule.name} placement="bottom-end">
                    <Link
                      style={{ textDecoration: "none" }}
                      component="button"
                      variant="body2"
                      onClick={() => {
                        handleClick(schedule);
                        setOpen(!open);
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              color: "#634699",
                              fontWeight: 700,
                              fontSize: "14px",
                              lineHeight: "19.6px",
                              textAlign: "left",
                            }}
                          >
                            {schedule.name}
                          </Typography>
                        }
                      />
                    </Link>
                  </Tooltip>
                </ListItem>
                <Divider variant="inset" component="li" />
              </List>
            </React.Fragment>
          ))):""}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFullListClose} sx={{color:"#634699",fontWeight:600}}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default SCHEDULE;
