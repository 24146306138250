import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuizStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import Dialog from "@mui/material/Dialog";
import TimerExpiredNew from "./TimerExpiredNew";

const timerStyle = (type) => {
  if (type === "sidebar") {
    return "#000000";
  } else {
    return "#FFFFFF";
  }
};
const Timer = ({ quiz, type }) => {
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  const { quizStatus } = useSelector((state) => state.testAttemptState);

  const [countdown, setCountdown] = useState(quiz.timelimit);

  useEffect(() => {
    if (countdown === 0) {
      dispatch(fetchQuizStatus(userid, quiz.id, "expired"));
      setIsDialogOpen(true);
    } else if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [countdown]);

  function fancyTimeFormat(duration) {
    var hrs = Math.floor(duration / 3600);
    var mins = Math.floor((duration % 3600) / 60);
    var secs = Math.floor(duration % 60);
    var ret = "";
    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
    <span
      style={
        quiz.timelimit - countdown > 10
          ? {
              fontWeight: 700,
              fontSize: "20px",
              color: timerStyle(type),
            }
          : {
              fontWeight: 700,
              fontSize: "20px",
              color: timerStyle(type),
            }
      }
    >
        {fancyTimeFormat(countdown)}
      </span>

      <Dialog open={isDialogOpen}
      PaperProps={{
        style: {
          background: "none",
          boxShadow: "none",
        },
      }} 
       sx={{
        "& .MuiDialog-container": {
          backgroundColor: "#F8F9FA", // Set your desired background color here
        },
      }}onClose={() => setIsDialogOpen(false)}>
  <TimerExpiredNew  />
</Dialog>

    </>
  );
};
export default Timer;
