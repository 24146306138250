/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class views/main/index.js
 * This JS file is intended to give an overall layout to the entire application and main entry point to the layout folder.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 25 Feb 2022
 */

import SIDEBAR from "./sidebar";
import { Button, Card, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dashboard from "../../views/students/dashboard";

import HEADER from "./header";
import GuardedRoute from "./GuardedRoute";
import NotFound from "../../views/main/NotFound";
import {
  fetchUserId,
  logoutUser,
} from "../../webservice/students/actions/AuthAction";
import {
  fetchEnrolledTests,
} from "../../webservice/students/actions/MyTestPackageAction";
import {
  resetValidateUserFunction,
  validateUserFunction,
} from "../../webservice/students/actions/GuestAccessAction";
import TestPackages from "../../views/students/course/content";
import TestDetail from "../../views/students/course/content/TestDetail";
import OrderConfirmation from "../../views/students/course/content/OrderConfirmation";
import CHECKOUT from "../../views/students/course/content/checkout";
import MyTestPackages from "../../views/students/test/packages";
import MySchedule from "../../views/students/schedule";
import TAKETESTINDEX from "../../views/students/dashboard/TakeTestIndex";
import Success from "../../views/students/schedule/success";
import ORDERBILL from "../../views/students/course/content/OrderBill";
import TestCustomize from "../../views/students/course/content/TestCustomize";
import LOGIN from "../../views/main/login/login";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import ProfileScreen from "./header/ProfileScreen";
import TestResults from "../../views/students/testresults";
import Notifications from "./header/Notifications";
import CartDetails from "./header/CartDetails";
import Needhelpsection from "./sidebar/Needhelpsection";
import HelpIndex from "./sidebar/HelpIndex";
import editicon from "../../assets/images/InvalidUrlImage.svg";

const USE_STYLES = makeStyles({
  mainContainer: {
    height: "100vh",
    overflow: "hidden",
  },
  rightContainer: {
    margin: "10px",
  },
  leftContainer: {
    flex: 0,
  },
});

const MAIN = () => {
  const classes = USE_STYLES();
  const userToken = window.localStorage.getItem("userToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const { userid } = useSelector((state) => state.authState);
  const [count, setCount] = useState(false);
  const [isOnline, setIsOnline] = useState(true);
  const [expanded, setExpanded] = useState(true);
  const { testList } = useSelector((state) => state.mytestpackagestate);
  const { validateUser } = useSelector((state) => state.guestAccessState);

  useEffect(() => {
    const isOnlineString = localStorage.getItem("isOnline");
    const isOnlineValue = JSON.parse(isOnlineString);
    setIsOnline(isOnlineValue);
  }, []);

  useEffect(() => {
    if (validateUser?.emailsent === false) {
      dispatch(resetValidateUserFunction());
    }
  }, [validateUser, dispatch]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (
      currentUrl === "http://localhost:3000/" ||
      currentUrl === "http://localhost:3000" ||
      currentUrl === "https://qa-student.cenablecare.com/" ||
      currentUrl === "https://qa-student.cenablecare.com" ||
      currentUrl === "https://student.cenablecare.com/" ||
      currentUrl === "https://student.cenablecare.com"
    ) {
      if (isOnline && userToken) {
        history.push("/dashboard");
      } else {
        history.push("/login");
      }
    }
  }, [isOnline, userToken, history]);

  useEffect(() => {
    dispatch(fetchUserId());
  }, [dispatch]);

  useEffect(() => {
    if (userid) dispatch(fetchEnrolledTests(userid));
  }, [userid, dispatch]);

  useEffect(() => {
    if (testList?.length > 0) {
      setCount(false);
    } else if (testList?.length === 0) {
      setCount(true);
    }
  }, [testList]);

  useEffect(() => {
    const checkSessionExpiry = () => {
      const loginTimestamp = localStorage.getItem("loginTimestamp");
      if (loginTimestamp) {
        const now = new Date().getTime();
        const loginTime = new Date(loginTimestamp).getTime();
        const elapsedTime = now - loginTime;
        const threeMinutesInMilliseconds = 12 * 60 * 60 * 1000; // 12 hour


        if (elapsedTime > threeMinutesInMilliseconds) {
          dispatch(logoutUser());
          localStorage.removeItem("userToken");
          localStorage.removeItem("loginTimestamp");
          history.push("/login");
        }
      }
    };

    checkSessionExpiry();
    const interval = setInterval(checkSessionExpiry, 60000); // Check every minute

    return () => clearInterval(interval);
  }, [dispatch, history]);

  const validPaths = [
    "/login",
    "/dashboard",
    "/login/taketestindex",
    "/test-packages",
    "/schedule/success",
    "/test-packages/orderconfirmation",
    "/test-packages/:id",
    "/Testresults",
    "/my-test-packages",
    "/schedule",
    "/orderbills",
    "/test-packages/:id/testcustomize",
    "/profilescreen",
    "/Notifications",
    "/CartDetails",
    "/HelpIndex",
  ];

  const isValidPath = validPaths.some((path) =>
    window.location.pathname.includes(path)
  );

  if (!isOnline) {
      localStorage.removeItem("userToken");
    return <Redirect to="/login" />;
  }
  return (
    <>
      {userToken && isValidPath && (
        <Grid container spacing={2} className={classes.mainContainer}>
          <Grid item xs className={classes.leftContainer}>
            <SIDEBAR expanded={expanded} setExpanded={setExpanded}/>
          </Grid>
          <Grid item xs className={classes.rightContainer}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <HEADER expanded={expanded} setExpanded={setExpanded}/>
              </Grid>
              <Grid item xs={12}>
                <Switch>
                  {/* {testList !== null && ( */}
                    <Route
                      path="/dashboard"
                      component={Dashboard}
                      auth={count}
                    />
                  {/* )} */}
                  <Route path="/login/taketestindex">
                    <TAKETESTINDEX />
                  </Route>
                  <Route path="/login" exact>
          <LOGIN /></Route>
                  <Route path="/test-packages/checkout/:id/:price">
                    <CHECKOUT />
                  </Route>
                  <Route path="/schedule/success/:timestamp">
                    <Success />
                  </Route>
                  <Route path="/test-packages/orderconfirmation/:id">
                    <OrderConfirmation />
                  </Route>
                  <Route path="/test-packages" exact>
                    <TestPackages />
                  </Route>
                  <Route path="/test-packages/:id">
                    <TestDetail />
                  </Route>
                  <Route path="/Testresults">
                    <TestResults />
                  </Route>
                  {/* {testList !== null && ( */}
                    <Route
                      path="/my-test-packages"
                      component={MyTestPackages}
                      auth={count}
                    />
                  {/* )} */}
                  {/* {testList !== null && ( */}
                    <Route
                      path="/schedule"
                      component={MySchedule}
                      auth={count}
                    />
                  {/* )} */}
                  <Route path="/orderbills/:id">
                    <ORDERBILL />
                  </Route>
                  <Route path="/test-packages/:id/testcustomize">
                    <TestCustomize />
                  </Route>
                  <Route path="/notfound">
                    <NotFound />
                  </Route>
                  <Route path="/profilescreen">
                    <ProfileScreen />
                  </Route>
                  <Route path="/Notifications">
                    <Notifications />
                  </Route>
                  <Route path="/CartDetails">
                    <CartDetails />
                  </Route>
                  <Route path="/HelpIndex">
                    <HelpIndex />
                  </Route>
                </Switch>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {!isValidPath && (
        <div style={{ textAlign: "center" }}>
          <img src={editicon} alt="edit" width="380px" height="380px" />
          <Grid item sx={{ display: "flex", justifyContent: "center", marginTop: "-40px" }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "60.45px",
                lineHeight: "95.83px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                color: "#8365BB",
              }}
            >
              Uh oh !
            </Typography>
          </Grid>
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "30.48px",
                lineHeight: "44.08px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                color: "#98A0AB",
              }}
            >
              It seems your URL is lost in space
            </Typography>
          </Grid>
          <br />
          <Button
            onClick={() => {
              if (userToken) {
                history.push("/dashboard");
              } else {
                history.push("/login");
              }
              window.location.reload();
            }}
            sx={{
              background: "#634699",
              color: "#FFFFFF",
              borderRadius: "5px",
              textTransform: "none",
              boxShadow: "none",
              padding: "5px 35px 5px 35px",
              fontWeight: 400,
              fontSize: "14px",
              "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
            }}
          >
            Beam Me Home
          </Button>
        </div>
      )}
    </>
  );
};
export default MAIN;
