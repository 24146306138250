import React, { useState } from "react";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Pagination,
  FormHelperText,
  Collapse,
  IconButton,
  ListItem,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Tooltip,Zoom,
} from "@mui/material";
import parse from "html-react-parser";
import { ExpandFilledIcon, ExpandIcon, Scheduleiconbtn } from "../../../../assets/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MatchNew from "./MatchNew";
import { useEffect } from "react";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { makeStyles } from "@mui/styles";

const USE_STYLES = makeStyles({
  customTooltip: {
    backgroundColor: '#634699',
    color: 'white',
    '& .MuiTooltip-arrow': {
      color: '#634699',
    },
  },})
const formatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const FeedbackQuestion = ({ page, quizAttemptDetail, count,show,comment }) => {
  const CLASSES = USE_STYLES();
  const [feedbackDetails, setFeedbackDetails] = useState({
    responsesummary: "",
    multiresponsesummary: [],
    rightAnswer: "",
    multirightanswer: [],
    matchrightanswer:[],
    matchanswer:[],
    comment: "",
    type: "",
  });
  const [fileList, setFileList] = useState([]);
  useEffect(() => {
    if (!quizAttemptDetail?.[0]?.questionlist) {
      setFeedbackDetails({
        ...feedbackDetails,
        responsesummary: quizAttemptDetail.responsesummary?.[0]|| "", // Set to an empty string by default
        multiresponsesummary: quizAttemptDetail?.multiresponsesummary || [],
        rightAnswer: quizAttemptDetail?.rightanswer || "", // Set to an empty string by default
        matchrightanswer:quizAttemptDetail?.matchrightanswer||[],
        matchanswer:quizAttemptDetail?.matchanswer||[],
        multirightanswer: quizAttemptDetail?.multirightanswer || [], // Set to an empty array by default
        comment: quizAttemptDetail?.comment?.[0],
        type: quizAttemptDetail?.submqqtype,
      });
      // Extract filenames when filesattached is an array
      if (Array.isArray(quizAttemptDetail?.filesattached) && quizAttemptDetail.filesattached.length > 0) {
        setFileList(quizAttemptDetail.filesattached);
      } else {
        setFileList([]); // If no filenames found, reset fileList to an empty array
      }
    } else {
      setFeedbackDetails({
        ...feedbackDetails,
        responsesummary: quizAttemptDetail[0].questionlist[page - 1].responsesummary|| "", // Set to an empty string by default
        multiresponsesummary: quizAttemptDetail[0].questionlist[page - 1].multiresponsesummary || [],
        rightAnswer: quizAttemptDetail[0].questionlist[page - 1].rightanswer || "", // Set to an empty string by default
        matchrightanswer:quizAttemptDetail[0].questionlist[page - 1].matchrightanswer||[],
        matchanswer:quizAttemptDetail[0].questionlist[page - 1].matchanswer||[],
        multirightanswer: quizAttemptDetail[0].questionlist[page - 1].multirightanswer || [], // Set to an empty array by default
        comment: quizAttemptDetail[0].questionlist[page - 1].comment,
        type: quizAttemptDetail[0].questionlist[page - 1].qtype,
      });
      // Extract filenames when filesattached is an array
      if (Array.isArray(quizAttemptDetail[0].questionlist[page - 1]?.filesattached)
          && quizAttemptDetail[0].questionlist[page - 1].filesattached.length > 0) {
        setFileList(quizAttemptDetail[0].questionlist[page - 1].filesattached);
      } else {
        setFileList([]); // If no filenames found, reset fileList to an empty array
      }
    }
  }, [page, quizAttemptDetail]);

  const [open, setOpen] = useState(false);
  const [expandOpen, setExpandOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(false);

  function extractRightAnswer(rightAnswer) {
    // Check if rightAnswer is an array
    if (Array.isArray(rightAnswer)) {
      // Filter out empty strings and trim whitespace
      const cleanedAnswers = rightAnswer.filter(answer => answer.trim() !== "");
      // Join the cleaned answers with a comma
      return cleanedAnswers.join(', ');
    } else if (typeof rightAnswer === "string") {
      // Remove HTML tags using a regex
      const cleanedAnswer = parse(rightAnswer);
      return cleanedAnswer;
    }
    // Return an empty string if rightAnswer is neither a string nor an array
    return "";
  }

// const [opens, setOpens] = useState(false);

  // const handleOpen = () => {
  //   setOpens(true);
  // };

  // const handleClose = () => {
  //   setOpens(false);
  // };
  
  // const handleDownload = (file) => {
  //   const downloadLink = document.createElement('a');
  //   downloadLink.href = file.fileurl || file;
  //   downloadLink.target = '_blank';
  //   downloadLink.download = file.filename || file;
  //   downloadLink.click();
  // };
  
  // const handlePreview = (file) => {
  //   const img = new Image();
  //   img.src = file.fileurl || file;
  //   img.onload = () => {
  //     // Open image in the dialog once it's loaded
  //     handleOpen();
  //   };
  // };

  const [opens, setOpens] = useState(false);

  const handleClose = () => {
    setOpens(false);
  };

  const handlePreview = (file) => {
    if (file.filetype === 'application/pdf' || file.filetype === 'image/jpeg' || file.filetype === 'image/png' || file.filetype === 'image/heic') {
      setOpens(true);
    } else {
      const previewUrl = generatePreviewUrl(file.fileurl,file.filetype);
      window.open(previewUrl, '_blank');
    }
  };

  const handleDownload = (file) => {
    window.open(file.fileurl, '_blank');
  };

  const generatePreviewUrl = (url,filetype) => {
    if (filetype === 'application/msword' || filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || filetype === 'application/vnd.ms-powerpoint' || filetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
      return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(url)}`;
    }
    return url; // For other file types, just return the URL
  };

  
  return (
    <>
     
        <Grid container>
          <Grid item xs={12}>
            <Card
              sx={{
                marginTop: "10px",
                paddingLeft: "16px",
                background: open ? "#634699" : "#FFFFFF",
              }}
            >
              <Grid container>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => setOpen(!open)}
                    aria-label="expand"
                    size="small"
                    sx={{
                      transform: !open ? "rotate(0deg)" : "rotate(90deg)",
                      marginLeft: "auto",
                      marginTop: "10px",
                    }}
                  >
                    {open ? <ExpandFilledIcon sx={{ fontSize: 18 }} /> : <ExpandIcon sx={{ fontSize: 18 }} />}
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                  variant="h2"
                    sx={{
                      fontWeight: 700,
                      fontStyle: "regular",
                      color: open ? "#FFFFFF" : "#634699",
                      fontSize: "16px",
                      lineHeight: "140%",
                      padding: "10px",
                    }}
                  >
                    Your Answer
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {feedbackDetails.type === "match" ? (
                 <Grid item xs={12} sx={{marginLeft:"103px",marginTop:"20px"}}>
                 <Grid
                   container
                   sx={{
                     width:"100%",
                   }}
                 >
                   <Grid item xs={7} md={4}>
                     <div>
                       <Typography
                         variant="h2"
                         sx={{
                           fontWeight: 700,
                           fontStyle: "regular",
                           color: "#634699",
                           fontSize: "18px",
                           lineHeight: "140%",
                           textAlign: "center",
       
                           marginBottom: "20px",
                         }}
                       >
                         Column A
                       </Typography>
                     </div>
                     <div className="match-the-following">
                       <div className="column">
                         {feedbackDetails.matchanswer?.columna.map((item, index) => {
                           const hasImage = item.includes("<img");
                           const itemText = hasImage ? item.replace(/<img(.*?)\/?>/g, "") : item;
                           const itemImageSrcMatch = hasImage ? item.match(/src="(.*?)"/) : null;
                           const itemImageSrc = itemImageSrcMatch ? itemImageSrcMatch[1] : null;
       
           // Remove the last backslash from the URL (if it exists)
           const cleanedItemImageSrc = itemImageSrc ? itemImageSrc.replace(/\\$/, "") : null;
       
                           return (
                             <div key={index} className="rowAoptions">
                               {hasImage && (
                                 <div style={{ height: "70px", width: "100px", padding: 0, margin: 0 }}>
                                   <img src={cleanedItemImageSrc} style={{ width: "100%", height: "100%", margin: 0 }} />
                                 </div>
                               )}
       
                               <Typography
                                 variant="h2"
                                 sx={{
                                   fontWeight: 400,
                                   fontStyle: "regular",
                                   color: "#634699",
                                   fontSize: "14px",
                                 }}
                               >
                                 {parse(itemText)}
                               </Typography>
       
                               {hasImage ? (
                                 <div className="divider-line-with-image" />
                               ) : (
                                 <div className="divider-line-without-image" />
                               )}
                             </div>
                           );
                         })}
                       </div>
                     </div>
                   </Grid>
                   {show ? (
               <Grid item xs={6} md={4}>
                 <Typography
                       variant="h2"
                       sx={{
                         fontWeight: 700,
                         fontStyle: "regular",
                         color: "#634699",
                         fontSize: "18px",
                         lineHeight: "140%",
                         textAlign: "center",
                         marginBottom: "20px",
                       }}
                     >
                       Column B
                     </Typography>
       
                     <div className="match-the-following">
                       <DragDropContext >
                         <Droppable droppableId="rowAoptions">
                           {(provided) => (
                             <div className="column" {...provided.droppableProps} ref={provided.innerRef}>
                               {feedbackDetails.matchanswer.columnb.map((item, index) => (
                                 <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={!show}>
                                   {(provided, snapshot) => (
                                     <div
                                       className="rowBoptions"
                                       ref={provided.innerRef}
                                       {...provided.dragHandleProps}
                                       {...provided.draggableProps}
                                       style={{
                                         backgroundColor: snapshot.isDragging ? "#634699" : "#fff",
       
                                         color: snapshot.isDragging ? " #fff" : "#634699",
       
                                         ...provided.draggableProps.style,
                                       }}
                                     >
                                       <Typography
                                         variant="h2"
                                         sx={{
                                           fontWeight: 400,
                                           fontStyle: "regular",
                                           fontSize: "14px",
                                         }}
                                       >
                                         {parse(item?.value)||parse(item)}
                                       </Typography>
                                     </div>
                                   )}
                                 </Draggable>
                               ))}
                               {provided.placeholder}
                             </div>
                           )}
                         </Droppable>
                       </DragDropContext>
                     </div>
               </Grid>
             ) : (
               <Grid item xs={6} md={6} 
               sx={{marginLeft:"58px"}}
               >
                  <Typography
                       variant="h2"
                       sx={{
                         fontWeight: 700,
                         fontStyle: "regular",
                         color: "#634699",
                         fontSize: "18px",
                         lineHeight: "140%",
                         textAlign: "center",
                         marginBottom: "20px",
                       }}
                     >
                       Column B
                     </Typography>
       
                     <div className="match-the-following">
                       <DragDropContext >
                         <Droppable droppableId="rowAoptions">
                           {(provided) => (
                             <div className="column" {...provided.droppableProps} ref={provided.innerRef}>
                              {feedbackDetails.matchanswer.columnb.map((item, index) => (
                                 <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={!show}>
                                   {(provided, snapshot) => (
                                     <div
                                       className="rowBoptions"
                                       ref={provided.innerRef}
                                       {...provided.dragHandleProps}
                                       {...provided.draggableProps}
                                       style={{
                                         backgroundColor: snapshot.isDragging ? "#634699" : "#fff",
       
                                         color: snapshot.isDragging ? " #fff" : "#634699",
       
                                         ...provided.draggableProps.style,
                                       }}
                                     >
                                       <Typography
                                         variant="h2"
                                         sx={{
                                           fontWeight: 400,
                                           fontStyle: "regular",
                                           fontSize: "14px",
                                         }}
                                       >
                                         {item?.value || item}
                                       </Typography>
                                     </div>
                                   )}
                                 </Draggable>
                               ))}
                               {provided.placeholder}
                             </div>
                           )}
                         </Droppable>
                       </DragDropContext>
                     </div>
               </Grid>
             )}
                   <Grid item xs={8} />
                 </Grid>
               </Grid>
              ) : (
                <Grid item xs={12}>
                  {feedbackDetails.responsesummary !== null ? (
                    <Typography
                    variant="h2"
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        color: "#000",
                        fontSize: 16,
                        lineHeight: "22px",
                        padding: "8px",
                        marginLeft: "30px",
                      }}
                    >
                      {feedbackDetails.multiresponsesummary.length > 0
              ? feedbackDetails.multiresponsesummary.map((summary, index) => (
                  <div key={index}>{parse(summary)}</div>
                ))
              : parse(feedbackDetails.responsesummary || "")}
            {/* {feedbackDetails.rightAnswer } */}
                    </Typography>
                  ) : null}
  {fileList.length > 0 && (
    <Grid item xs={12}>
      <Typography
      variant="h2"
        sx={{
          fontWeight: 400,
          fontStyle: "regular",
          color: "#000",
          fontSize: 16,
          lineHeight: "22px",
          padding: "8px",
          marginLeft: "30px",
        }}
      >
        
         {fileList.map((file, index) => (
          // <span key={index}>
          //   <a href={file.fileurl||file} target="_blank" rel="noopener noreferrer">
          //     {file.filename||file}
          //   </a>
          // </span>
          <div key={index}>
          <Box
            sx={{
              boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.13)",
              marginLeft: "6px",
              background: "#FFFFFF",
              borderRadius: "5px",
              margin:"5px"
            }}
          >
            <div style={{ display: "flex" }}>
              <IconButton
                position="fixed"
                sx={{
                  width: "20px",
                  height: "20px",
                  margin: "8px 2px 8px 10px",
                  backgroundColor: "#634699",
                  border: "#634699",
                  color: "white",
                  cursor: "auto",
                  "&:hover": {
                    backgroundColor: "#634699",
                  },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 400,
                    fontStyle: "regular",
                    color: "white",
                    fontSize: "10px",
                    lineHeight: "140%",
                    padding: "7px",
                  }}
                >
                  {index + 1}
                </Typography>
              </IconButton>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 400,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "14px",
                  lineHeight: "140%",
                  padding: "7px",
                }}
              >
                {file.filename || file}
              </Typography>
{/* {file.filetype === 'image/png' || file.filetype === 'image/jpeg' ? (
      <Tooltip title="Preview" placement="top"  TransitionComponent={Zoom} classes={{ tooltip: CLASSES.customTooltip }} arrow><ViewInArOutlinedIcon  sx={{color:"#634699",fontSize: 20,margin:"8px",cursor:"pointer"}} onClick={() => handlePreview(file)}/></Tooltip> 
    ) : <Tooltip title="Download" placement="top"  TransitionComponent={Zoom} classes={{ tooltip: CLASSES.customTooltip }} arrow><FileDownloadOutlinedIcon sx={{color:"#634699",fontSize: 22,margin:"7px",cursor:"pointer"}} onClick={() => handleDownload(file)}/></Tooltip>
  }
              <Dialog open={opens}  BackdropProps={{style: {backgroundColor: '#634699',opacity:"60%"},}}  
                   PaperProps={{
                      style: {
                        background: "#fff",
                        boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
                        borderRadius: "5px",
                        padding: "1%",
                      },
                    }}>
                      <div style={{ textAlign: 'right',  }}>
                        <HighlightOffSharpIcon onClick={handleClose} sx={{cursor:"pointer",color:"#634699"}}/>
                        </div>
                        <img
                        src={file.fileurl || file}
                        alt={file.filename || 'Preview'}
                        style={{ width: 'auto', height: 'auto' }}
                        />
                        </Dialog> */}
                         {/* {file.filetype === 'image/png' || file.filetype === 'image/jpeg' || file.filetype === 'image/heic' || file.filetype === 'application/pdf' ? ( */}
        {file.filetype ==="image/heic"?"":<Tooltip title="Preview" placement="top" TransitionComponent={Zoom} classes={{ tooltip: CLASSES.customTooltip }} arrow>
          <ViewInArOutlinedIcon sx={{ color: "#634699", fontSize: 20, margin: "8px", cursor: "pointer" }} onClick={() => handlePreview(file)} />
        </Tooltip>}
      {/* ) : ( */}
        <Tooltip title="Download" placement="top" TransitionComponent={Zoom} classes={{ tooltip: CLASSES.customTooltip }} arrow>
          <FileDownloadOutlinedIcon sx={{ color: "#634699", fontSize: 22, margin: "7px", cursor: "pointer" }} onClick={() => handleDownload(file)} />
        </Tooltip>
      {/* )} */}

      <Dialog open={opens} onClose={handleClose} BackdropProps={{ style: { backgroundColor: '#634699', opacity: "60%" } }}
        PaperProps={{
          style: {
            background: "#fff",
            boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            padding: "1%",
          },
        }}>
        <div style={{ textAlign: 'right' }}>
          <HighlightOffSharpIcon onClick={handleClose} sx={{ cursor: "pointer", color: "#634699" }} />
        </div>
        {file.filetype === 'application/pdf' ? (
          <iframe src={file.fileurl} title={file.filename} style={{ width: '100%', height: '80vh' }} />
        ) : (
          <img
            src={file.fileurl || file}
            alt={file.filename || 'Preview'}
            style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '80vh' }}
          />
        )}
      </Dialog>
              
            </div>
          </Box>{" "}
        </div>
          
        ))}
      </Typography>
    </Grid>
  )}
                </Grid>
              )}
            </Collapse>
          </Grid>
        </Grid>
     
        {/* {feedbackDetails.comment===undefined ? (
        <Grid container >
          <Grid item xs={12}>
            <Card
              sx={{
                marginTop: "10px",
                paddingLeft: "16px",
                background: expandOpen ? "#634699" : "#FFFFFF",
              }}
            >
              <Grid container>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => setExpandOpen(!expandOpen)}
                    aria-label="expand"
                    size="small"
                    sx={{
                      transform: !expandOpen ? "rotate(0deg)" : "rotate(90deg)",
                      marginLeft: "auto",
                      marginTop: "10px",
                    }}
                  >
                    {expandOpen ? <ExpandFilledIcon sx={{ fontSize: 18 }} /> : <ExpandIcon sx={{ fontSize: 18 }} />}
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                  variant="h2"
                    sx={{
                      fontWeight: 700,
                      fontStyle: "regular",
                      color: expandOpen ? "#FFFFFF" : "#634699",
                      fontSize: "16px",
                      lineHeight: "140%",
                      padding: "10px",
                    }}
                  >
                    Feedback
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={expandOpen} timeout="auto" unmountOnExit>
              {feedbackDetails.comment ? (
                <Grid item xs={12}>
                  <Typography
                  variant="h2"
                    sx={{
                      fontWeight: 400,
                      fontStyle: "regular",
                      color: "#000",
                      fontSize: 16,
                      lineHeight: "22px",
                      padding: "8px",
                      marginLeft: "30px",
                    }}
                  >
                    {parse(feedbackDetails.comment)}
                  </Typography>
                </Grid>
              ) : null}
            </Collapse>
          </Grid>
        </Grid>
         ):null} */}
     
      
        <Grid container>
          <Grid item xs={12}>
            <Card
              sx={{
                marginTop: "10px",
                paddingLeft: "16px",
                background: expand ? "#634699" : "#FFFFFF",
              }}
            >
              <Grid container>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => setExpand(!expand)}
                    aria-label="expand"
                    size="small"
                    sx={{
                      transform: !expand ? "rotate(0deg)" : "rotate(90deg)",
                      marginLeft: "auto",
                      marginTop: "10px",
                    }}
                  >
                    {expand ? <ExpandFilledIcon sx={{ fontSize: 18 }} /> : <ExpandIcon sx={{ fontSize: 18 }} />}
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                  variant="h2"
                    sx={{
                      fontWeight: 700,
                      fontStyle: "regular",
                      color: expand ? "#FFFFFF" : "#634699",
                      fontSize: "16px",
                      lineHeight: "140%",
                      padding: "10px",
                    }}
                  >
                    Correct Answer
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              {feedbackDetails.type === "match" ? (
                 <Grid item xs={12} sx={{marginLeft:"103px",marginTop:"20px"}}>
                 <Grid
                   container
                   sx={{
                     width:"100%",
                   }}
                 >
                   <Grid item xs={7} md={4}>
                     <div>
                       <Typography
                         variant="h2"
                         sx={{
                           fontWeight: 700,
                           fontStyle: "regular",
                           color: "#634699",
                           fontSize: "18px",
                           lineHeight: "140%",
                           textAlign: "center",
       
                           marginBottom: "20px",
                         }}
                       >
                         Column A
                       </Typography>
                     </div>
                     <div className="match-the-following">
                       <div className="column">
                         {feedbackDetails.matchrightanswer?.columna.map((item, index) => {
                           const hasImage = item.includes("<img");
                           const itemText = hasImage ? item.replace(/<img(.*?)\/?>/g, "") : item;
                           const itemImageSrcMatch = hasImage ? item.match(/src="(.*?)"/) : null;
                           const itemImageSrc = itemImageSrcMatch ? itemImageSrcMatch[1] : null;
       
           // Remove the last backslash from the URL (if it exists)
           const cleanedItemImageSrc = itemImageSrc ? itemImageSrc.replace(/\\$/, "") : null;
       
                           return (
                             <div key={index} className="rowAoptions">
                               {hasImage && (
                                 <div style={{ height: "70px", width: "100px", padding: 0, margin: 0 }}>
                                   <img src={cleanedItemImageSrc} style={{ width: "100%", height: "100%", margin: 0 }} />
                                 </div>
                               )}
       
                               <Typography
                                 variant="h2"
                                 sx={{
                                   fontWeight: 400,
                                   fontStyle: "regular",
                                   color: "#634699",
                                   fontSize: "14px",
                                 }}
                               >
                                 {parse(itemText)}
                               </Typography>
       
                               {hasImage ? (
                                 <div className="divider-line-with-image" />
                               ) : (
                                 <div className="divider-line-without-image" />
                               )}
                             </div>
                           );
                         })}
                       </div>
                     </div>
                   </Grid>
                   {show ? (
               <Grid item xs={6} md={4}>
                 <Typography
                       variant="h2"
                       sx={{
                         fontWeight: 700,
                         fontStyle: "regular",
                         color: "#634699",
                         fontSize: "18px",
                         lineHeight: "140%",
                         textAlign: "center",
                         marginBottom: "20px",
                       }}
                     >
                       Column B
                     </Typography>
       
                     <div className="match-the-following">
                       <DragDropContext >
                         <Droppable droppableId="rowAoptions">
                           {(provided) => (
                             <div className="column" {...provided.droppableProps} ref={provided.innerRef}>
                               {feedbackDetails.matchrightanswer.columnb.map((item, index) => (
                                 <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={!show}>
                                   {(provided, snapshot) => (
                                     <div
                                       className="rowBoptions"
                                       ref={provided.innerRef}
                                       {...provided.dragHandleProps}
                                       {...provided.draggableProps}
                                       style={{
                                         backgroundColor: snapshot.isDragging ? "#634699" : "#fff",
       
                                         color: snapshot.isDragging ? " #fff" : "#634699",
       
                                         ...provided.draggableProps.style,
                                       }}
                                     >
                                       <Typography
                                         variant="h2"
                                         sx={{
                                           fontWeight: 400,
                                           fontStyle: "regular",
                                           fontSize: "14px",
                                         }}
                                       >
                                         {parse(item?.value)||parse(item)}
                                       </Typography>
                                     </div>
                                   )}
                                 </Draggable>
                               ))}
                               {provided.placeholder}
                             </div>
                           )}
                         </Droppable>
                       </DragDropContext>
                     </div>
               </Grid>
             ) : (
               <Grid item xs={6} md={6} 
               sx={{marginLeft:"58px"}}
               >
                  <Typography
                       variant="h2"
                       sx={{
                         fontWeight: 700,
                         fontStyle: "regular",
                         color: "#634699",
                         fontSize: "18px",
                         lineHeight: "140%",
                         textAlign: "center",
                         marginBottom: "20px",
                       }}
                     >
                       Column B
                     </Typography>
       
                     <div className="match-the-following">
                       <DragDropContext >
                         <Droppable droppableId="rowAoptions">
                           {(provided) => (
                             <div className="column" {...provided.droppableProps} ref={provided.innerRef}>
                              {feedbackDetails.matchrightanswer.columnb.map((item, index) => (
                                 <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={!show}>
                                   {(provided, snapshot) => (
                                     <div
                                       className="rowBoptions"
                                       ref={provided.innerRef}
                                       {...provided.dragHandleProps}
                                       {...provided.draggableProps}
                                       style={{
                                         backgroundColor: snapshot.isDragging ? "#634699" : "#fff",
       
                                         color: snapshot.isDragging ? " #fff" : "#634699",
       
                                         ...provided.draggableProps.style,
                                       }}
                                     >
                                       <Typography
                                         variant="h2"
                                         sx={{
                                           fontWeight: 400,
                                           fontStyle: "regular",
                                           fontSize: "14px",
                                         }}
                                       >
                                         {item?.value || item}
                                       </Typography>
                                     </div>
                                   )}
                                 </Draggable>
                               ))}
                               {provided.placeholder}
                             </div>
                           )}
                         </Droppable>
                       </DragDropContext>
                     </div>
               </Grid>
             )}
                   <Grid item xs={8} />
                 </Grid>
               </Grid>
              ) : (
                <Grid item xs={12}>
                   {feedbackDetails.rightAnswer !== null ? (
                    <Typography
                    variant="h2"
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        color: "#000",
                        fontSize: 16,
                        lineHeight: "22px",
                        padding: "8px",
                        marginLeft: "30px",
                      }}
                    >
                      {feedbackDetails.multirightanswer.length > 0
            ? feedbackDetails.multirightanswer.map((answer, index) => (
                <div key={index}>{parse(answer)}</div>
              ))
            : extractRightAnswer(feedbackDetails.rightAnswer) || ""}
            {/* {feedbackDetails.rightAnswer } */}
                  </Typography>
                  ) : null}
                </Grid>
              )}
            </Collapse>
          </Grid>
        </Grid>
     
    </>
  );
};

export default FeedbackQuestion;
