/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class ScheduleForm.js
 * This JS file shows the form for schedule the meetings with Tutor
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 23 may 2022
 */

import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Alert,
  AlertTitle,
  Dialog,
} from "@mui/material";
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from 'ckeditor5-build-classic-extended/build/ckeditor';
// import ClassicEditor from 'ckeditor5-build-classic-mathtype'
// import ClassicEditor from "ckeditor5-classic-with-mathtype";
import ClassicEditor from 'ckeditor5-build-classic-extended/build/ckeditor';
// import "ckeditor5-classic-with-mathtype/build/translations/en";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import moment from "moment-timezone";
// import "../../../views/students/test/packages/MathType.css";



import {
  createSchedule,
  getBookedTimes,
  resetBookedTimesState,
} from "../../../webservice/students/actions/ScheduleAction";
import { getTimeSlots } from "../../../utils/AppConstants";
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#634699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#634699",
    },
    "&:hover fieldset": {
      borderColor: "#634699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#634699",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ck-editor__main > .ck-editor__editable": {
      color: "#634699",
      minHeight: "100px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "&.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
    {
      color: "#634699",
      minHeight: "100px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      background: "transparent",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      color: "#634699",
      minHeight: "100px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      background: "transparent",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "9.25635px 9.25635px 0px 0px",
    },

  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));

const USE_STYLES = makeStyles({
  text: {
    color: "#634699",
    marginLeft: "23px",
    marginRight: "23px",
  },
  field: {
    backgroundColor: "#634699",
    borderRadius: 5,
    width: "40%",
  },
  select: {
    "& .MuiSvgIcon-root": {
      color: "#ffffff",
    },
  },
});

const SCHEDULEFORM = (props) => {
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const { userid } = useSelector((state) => state.authState);
  const { date, sectionid, packageid } = props;
  let dateStamp = date.getTime() / 1000;
  const CLASSES = USE_STYLES();
  const classes = useStyles();
  const HISTORY = useHistory();
  const [state, setState] = useState({
    title: "",
    time: "",
  });
  const { title, time } = state;
  const { bookedTimes } = useSelector((state) => state.scheduleState);
  const [bookedTimesArray, setBookedTimesArray] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);

  const dispatch = useDispatch();
  const selectedTime = time.split("-")[0];
  const selectedHour = selectedTime.split(":")[0];
  const selectedMinute = selectedTime.split(":")[1]; 
  const selectedDateTime = moment.tz(date, "Australia/Melbourne");
  selectedDateTime.hours(selectedHour);
  selectedDateTime.minutes(selectedMinute);
  const newCreatedTimestamp = Math.floor(selectedDateTime.toDate().getTime() / 1000);

  useEffect(() => {
    dispatch(getBookedTimes(userid, packageid, sectionid, dateStamp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (date) {
  //     const selectedDateObj = new Date(date);
  //     const dayOfWeek = selectedDateObj.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

  //     let startTime, endTime;

  //     if (dayOfWeek >= 1 && dayOfWeek <= 5) {
  //       // Weekdays: 3pm to am
  //       startTime = "15:00";
  //       endTime = "20:45";
  //     } else {
  //       // Weekends: 6am to 12am
  //       startTime = "06:00";
  //       endTime = "21:00";
  //     }

  //     if (bookedTimes !== null) {
  //       setTimeSlots(getTimeSlots(startTime, endTime));

  //       if (bookedTimes?.length) {
  //         let bookedSlot = bookedTimesArray;
  //         bookedTimes.forEach((element) => {
  //           let startTime =
  //             new Date(element.timestart * 1000).getHours() +
  //             ":" +
  //             new Date(element.timestart * 1000)
  //               .getMinutes()
  //               .toString()
  //               .padStart(2, 0);
  //           let endTime =
  //             new Date(
  //               (parseInt(element.timestart) + parseInt(element.timeduration)) *
  //               1000
  //             ).getHours() +
  //             ":" +
  //             new Date(
  //               (parseInt(element.timestart) + parseInt(element.timeduration)) *
  //               1000
  //             )
  //               .getMinutes()
  //               .toString()
  //               .padStart(2, 0);
  //           bookedSlot.push(startTime + "-" + endTime);
  //         });
  //         setBookedTimesArray(bookedSlot);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [date, bookedTimes]);
  useEffect(() => {
    if (date) {
      const selectedDateObj = new Date(date);
      const dayOfWeek = selectedDateObj.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

      let startTime, endTime;

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        // Weekdays: 3pm to 9am
        startTime = "15:00";
        endTime = "20:45";
      } else {
        // Weekends: 6am to 12am
        startTime = "06:00";
        endTime = "21:00";
      }

      // Helper function to check if a slot is within the blocked time
      const isSlotBlocked = (slotStart, slotEnd, blockedStart, blockedEnd) => {
        return (moment(slotStart, 'HH:mm').isBefore(moment(blockedEnd, 'HH:mm')) &&
                moment(slotEnd, 'HH:mm').isAfter(moment(blockedStart, 'HH:mm')));
      };

      if (bookedTimes !== null) {
        const blockedSlots = [{ start: '18:30', end: '19:30' }]; // Blocked time slot
        const allTimeSlots = getTimeSlots(startTime, endTime);

        // Filter out the blocked time slots
        const filteredTimeSlots = allTimeSlots.filter((slot) => {
          return !blockedSlots.some((blocked) => 
            isSlotBlocked(slot.start, slot.end, blocked.start, blocked.end)
          );
        });

        setTimeSlots(filteredTimeSlots);

        if (bookedTimes?.length) {
          let bookedSlot = bookedTimesArray;
          bookedTimes.forEach((element) => {
            let startTime = moment
              .unix(element.timestart)
              .tz('Australia/Melbourne')
              .format('HH:mm');
            let endTime = moment
              .unix(element.timestart)
              .tz('Australia/Melbourne')
              .add(15, 'minutes') // Add 15 minutes to the start time
              .format('HH:mm');
            bookedSlot.push(startTime + "-" + endTime);
          });
          setBookedTimesArray(bookedSlot);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, bookedTimes]);


  const HANDLE_CHANGE = (event, editor) => {
    const data = editor.getData();
    setValue(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      createSchedule(
        userid,
        packageid,
        sectionid,
        title,
        newCreatedTimestamp,
        value
      )
    );
    dispatch(resetBookedTimesState());
    setOpen(!open);
  };

  const onCloseHandler = () => {
    setOpen(!open);
    HISTORY.push(`/schedule/success/${newCreatedTimestamp}`);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setState({ ...state, [name]: value });
  };
  function SpecialCharactersExtended(editor) {
    // Mathematical Symbols
    editor.plugins.get('SpecialCharacters').addItems('Mathematical', [
        { title: 'alpha', character: 'α' },
        { title: 'beta', character: 'β' },
        { title: 'gamma', character: 'γ' },
        { title: 'delta', character: 'δ' },
        { title: 'theta', character: 'θ' },
        { title: 'epsilon', character: 'ε' },
        { title: 'mu', character: 'μ' },
        { title: 'rho', character: 'ρ' },
        { title: 'sigma', character: 'σ' },
        { title: 'perpendicular', character: '⊥' },
        { title: 'triangle', character: '△' },
        { title: 'subset of', character: '⊂' },
        { title: 'super of', character: '⊃' },
        { title: 'subset of or equal to', character: '⊆' },
        { title: 'super of or equal to', character: '⊇' },
        { title: 'square subset of', character: '⊏' },
        { title: 'square super of', character: '⊐' },
        { title: 'square subset of or equal to', character: '⊑' },
        { title: 'square super of or equal to', character: '⊒' },
        { title: 'therefore', character: '∴' },
        { title: 'because', character: '∵' },
        { title: 'parallel to', character: '∥' },
        { title: 'not parallel to', character: '⊮' },
        { title: 'measured angle', character: '∡' },
        { title: 'spherical angle', character: '∢' },
        { title: 'ohms', character: 'Ω' },
        { title: 'right angle', character: '∟' },
        { title: 'xi', character: 'ξ' },
        
        // Add more mathematical symbols as needed
    ]);

    // Arrows
    editor.plugins.get('SpecialCharacters').addItems('Arrows', [
        { title: 'reversible reaction arrow', character: '⇌' },
        { title: 'lift right arrows', character: '⇄' },
        { title: 'reversible reaction arrow', character: '↔' },
        { title: 'top arrow', character: '↑' },
        { title: 'down arrow', character: '↓' },
        // Add more arrows as needed
    ]);

    // Chemical Symbols
    editor.plugins.get('SpecialCharacters').addItems('Chemical', [
        { title: '(aq)', character: '(aq)' },
        { title: '(s)', character: '(s)' },
        { title: '(g)', character: '(g)' },
        { title: '(l)', character: '(l)' },
        // Add more chemical symbols as needed
    ]);
}

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={5}>
                <Grid item xs={12} align="center">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "24px",
                      fontStyle: "regular",
                      lineHeight: "55.54px",
                      color: "#222222",
                    }}
                  >
                    {new Date(props.date)
                      .toDateString()
                      .substring(
                        4,
                        new Date(props.date * 1000).toDateString().length
                      )}
                  </Typography>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={1} className={CLASSES.text}>
                    <Typography sx={{
                      fontWeight: 700,
                      fontSize: "16px",
                    }}>Title</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <CssTextField
                      id="outlined-basic"
                      label="Enter Topic here"
                      variant="outlined"
                      fullWidth
                      required
                      name="title"
                      onChange={handleChange}
                      value={title}
                    ></CssTextField>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={1} className={CLASSES.text}>
                    <Typography sx={{
                      fontWeight: 700,
                      fontSize: "16px",
                    }}>Time Slot</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <FormControl
                      sx={{ marginLeft: 0, minWidth: "30%", color: "white" }}
                      className={CLASSES.field}
                      required
                    >
                      <InputLabel
                        htmlFor="grouped-select"
                        sx={{ color: "white" }}
                      >
                        Time
                      </InputLabel>
                      <Select
                        className={CLASSES.select}
                        id="grouped-select"
                        label="Grouping"
                        name="time"
                        onChange={handleChange}
                        value={time}
                        required
                        sx={{ color: "white" }}
                      >
                        {timeSlots && timeSlots.length
                          ? timeSlots.map((timevalue, index) => {
                            let slot = `${timevalue.start}-${timevalue.end}`;
                            let getTime = `${new Date().getHours()}:${new Date().getMinutes()}`;
                            let currTime = moment(getTime, "HH:mm");
                            let pastTime =
                              new Date().getTime() >=
                              new Date(props.date).getTime() &&
                              currTime.isAfter(
                                moment(timevalue.start, "HH:mm")
                              );

                            return (
                              <MenuItem
                                key={index}
                                value={slot}
                                sx={{
                                  color: "#634699",
                                  backgroundColor: "#F3F3F3",
                                  "&:hover": {
                                    background: "#C4C4C4", color: "#634699",
                                  },
                                  "&.Mui-selected": {
                                    background: "#634699", color: "white",
                                    "&:hover": {
                                      background: "#634699", color: "white",
                                    },
                                  }
                                }}
                                disabled={
                                  bookedTimesArray.includes(slot) || pastTime
                                }
                              >
                                {slot}
                              </MenuItem>
                            );
                          })
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={1} className={CLASSES.text}>
                    <Typography sx={{
                      fontWeight: 700,
                      fontSize: "16px",
                    }}>Details</Typography>
                  </Grid>
                  <Grid item xs={10} className={classes.richTextEditor}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={value}
                      required
                      onChange={HANDLE_CHANGE}
                      config={{
                        toolbar: {
                          items: [
                            "heading",
                            "MathType",
                            "ChemType",'specialCharacters','subscript', 'superscript',
                            "|",
                            "bold",
                            "italic",
                            "link",
                            "bulletedList",
                            "numberedList",
                            "insertTable",
                            "blockQuote",
                            "undo",
                            "redo",
                          ],
                        },
                        language: "en",
                      }}
                      onReady={(editor) => {
                        // Call the function to add extra special characters
                        SpecialCharactersExtended(editor);
                      }}
                    />
                    {/* <CKEditor
                      editor={ClassicEditor}
                      data={value}
                      required
                      onChange={HANDLE_CHANGE}
                    /> */}
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={10} md={12} sm={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Grid item xs={12} display="flex"
                    justifyContent="flex-end">
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        backgroundColor: "#634699",
                        "&:hover": { backgroundColor: "#634699" },
                      }}
                    >
                      Save
                    </Button>
                    <Dialog open={open} onClose={onCloseHandler}>
                      <Alert severity="success" color="info">
                        <AlertTitle>Success!</AlertTitle>
                        You have meeting with your tutor
                      </Alert>
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SCHEDULEFORM;
