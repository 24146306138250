/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TrueFalseNew.jsx
 * This JSX file is for showing the True or False Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 01 Feb 2023
 */
import { Box, CardMedia, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import { McqIcon } from "../../../../assets/icons";
import { useEffect } from "react";
import { useState } from "react";
import { fetchQuizStatus } from "../../../../webservice/students/actions/TestAttemptAction";

const USE_STYLES = makeStyles(() => ({
  icon: {
    height: "10px",
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));
const TrueFalseNew = ({ question, selectedAnswer, setSelectedAnswer, handleChange, count, show,quizStatus,quiz }) => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialog(true);
    setImageSrc(src);
  };
  const { userid } = useSelector((state) => state.authState);
  useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
    if (matchingAnswer) {
        // If there is a matching answer in quizStatus, update questionStatusResponse
        const updatedAnswer = matchingAnswer.answer;
        if (!selectedAnswer) {
            dispatch(fetchQuizStatus(userid, quiz.id));
        }
    }
  }, [questionStatusResponse, quizStatus, question?.questionid, userid]);
  useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
  if (matchingAnswer) {
      // If there is a matching answer in quizStatus, update questionStatusResponse
      const updatedAnswer = matchingAnswer.answer ;
      if ( updatedAnswer!== "") {
        questionStatusResponse[count].answer = updatedAnswer;
        
          questionStatusResponse[count]?.answer === "1" ? setSelectedAnswer("True") : setSelectedAnswer("False");
        }
        else {
          questionStatusResponse[count].answer = "";
          setSelectedAnswer("");
          
        }
    } else {
      // If no matching answer found in quizstatus, use default logic
    if (questionStatusResponse[count]?.answer !== "") {
      questionStatusResponse[count]?.answer === 1 ? setSelectedAnswer("True") : setSelectedAnswer("False");
    }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, quizStatus, question?.questionid, questionStatusResponse, setSelectedAnswer]);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <div>
      <Grid container sx={{ marginTop: "2%" }}>
        <Grid item xs={1.5}  md={1.5}/>
        <Grid item xs={5.2} md={7.3}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "0px",
            }}
          >
            {question.slot ? question.slot : ""}
          </Typography>{" "}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}> 
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "30px",
            }}
          >
            {parse(question?.questiontext || question.question, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                  src={domNode.attribs.src}
                  style={{
                    maxWidth: '65%', // Ensures the image fits within the parent container
                    maxHeight: '65%', // Ensures the image fits within the parent container
                    objectFit: 'contain', // Maintains the aspect ratio of the image
                    cursor: 'pointer'
                  }}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
          </Typography>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
          {show && question?.defaultmark !== null ? (
  <Typography
    variant="h2"
    sx={{
      fontWeight: 700,
      fontStyle: "regular",
      color: "#634699",
      fontSize: "14px",
      lineHeight: "150%",
      paddingBottom: "30px",
    }}
  >
    ({question?.defaultmark ? formatter.format(question.defaultmark) : ""} Mark)
  </Typography>) : null}
</div>
          <Grid item container className={CLASSES.divContainer} />
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "5px",
            }}
          >
            Options:
          </Typography>
          {(question?.answers || question?.truefalseanswer)?.map((option, index) => {
            const answer = option.answer || option;
            const parsedOption = parse(answer);
            return (
              <Box
                key={index}
                onClick={() => {
                  handleChange(parsedOption === "True" ? 1 : 0);
                  setSelectedAnswer(parsedOption);
                }}
                sx={{
                  background: selectedAnswer === parsedOption ? "#634699" : "#FFFFFF",
                  boxShadow:
                  !show
                  ? "none"
                  : selectedAnswer === parsedOption ? "1px 7px 25px #634699" : "1px 7px 25px rgba(0, 0, 0, 0.11)",
                  borderRadius: "5px",
                  padding: "1%",
                  fontWeight: "400",
                  color: selectedAnswer === parsedOption ? "#FFFFFF" : "#000000",
                  width: "250px",
                  margin: "1% 0% 0% 0%",
                  display: "flex",
                  alignItems: "center",
                  cursor: !show ? "auto" : "pointer",
                  fontSize: "14px",
                  "&:hover": { backgroundColor: !show ? "none" : "#634699", color: !show ? "none" : "#FFFFFF" },
                }}
              >
                <McqIcon
                  sx={{
                    stroke: selectedAnswer === parsedOption ? "#FFFFFF" : "#634699",
                  }}
                  className={CLASSES.icon}
                />
                <Box>{parsedOption}</Box>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

export default TrueFalseNew;
