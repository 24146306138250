import React from "react";
import OfflineHeader from "../../../../layouts/main/header/OfflineHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchQuizAttempt } from "../../../../webservice/students/actions/MyTestPackageAction";
import { Button, Grid, Typography } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import LOGO from "../../../main/ui_component/logo";
import {
  GradeA,
  GradeAPlus,
  GradeB,
  GradeBPlus,
  GradeC,
  GradeCPlus,
  GradeD,
  GradeDPlus,
  GradeE,
  GradeUG,
  GradeF,
  NotGrade,
} from "../../../../assets/icons";

const FilterImage = (grade) => {
  if (grade === "A") {
    return [
      <GradeA sx={{ width: "251px", height: "90px" }} />,
      "\"Success is no accident. It is hard work, perseverance, learning, studying, sacrifice,\n and most of all, love of what you are doing or learning to do.\"",
      "Pele",
      "#37AB97",
      "Excellent Performance",
    ];
  } else if (grade === "A+") {
    return [
      <GradeAPlus sx={{ width: "251px", height: "90px" }} />,
      "\"The only way to do great work is to love what you do.\"",
      "Steve Jobs",
      "#37AB97",
      "Exceptional Performance",
    ];
  } else if (grade === "B") {
    return [
      <GradeB sx={{ width: "251px", height: "90px" }} />,
      "\"Believe in yourself and all that you are. Know that there is something inside you \n that is greater than any obstacle\"",
      "Christian D. Larson",
      "#008DB5",
      "Good Performance",
    ];
  }  else if (grade === "B+") {
    return [
      <GradeBPlus sx={{ width: "251px", height: "90px" }} />,
      "\"You are braver than you believe, stronger than you seem, and smarter than you think.\"",
      "A.A. Milne (Winnie the Pooh)",
      "#008DB5",
      "Very Good Performance",
    ];
  } else if (grade === "C") {
    return [
      <GradeC sx={{ width: "251px", height: "90px" }} />,
      "\"Believe you can and you're halfway there\"",
      "Theodore Roosevelt",
      "#FF8C69",
      "Adequate Performance",
    ];
  }  else if (grade === "C+") {
    return [
      <GradeCPlus sx={{ width: "251px", height: "90px" }} />,
      "\"Don't watch the clock; do what it does. Keep going.\"",
      "Sam Levenson",
      "#FF8C69",
      "Satisfactory Performance",
    ];
  } else if (grade === "D") {
    return [
      <GradeD sx={{ width: "251px", height: "90px" }} />,
      "\"It does not matter how slowly you go as long as you do not stop.\"",
      "Confucius",
      "#ED6182",
      "Minimal Performance",
    ];
  } else if (grade === "D+") {
    return [
      <GradeDPlus sx={{ width: "251px", height: "90px" }} />,
      "\"You don't have to be great to start, but you have to start to be great.\"",
      "Zig Ziglar",
      "#ED6182",
      "Basic Performance",
    ];
  } else if (grade === "E") {
    return [
      <GradeE sx={{ width: "251px", height: "80px" }} />,
      "\"Our greatest glory is not in never falling, but in rising every time we fall.\"",
      "Confucius",
      "#B82620",
      "Below Average Performance",
    ];
  } else if (grade === "F") {
    return [
      <GradeF sx={{ width: "251px", height: "90px" }} />,
      "Every failure is a step to success.",
      "William Whewell",
      "#B82620",
      "lost out",
    ];
  }else if (grade === "UG") {
    return [
      <GradeUG sx={{ width: "251px", height: "90px" }} />,
      "\"Believe in yourself and all that you are. Know that there is something inside you that is greater than any obstacle.\"",
      "Christian D. Larson",
      "#B82620",
      "Ungraded",
    ];
  }else if (grade === "") {
    return [
      <NotGrade sx={{ width: "251px", height: "177px" }} />,
      <div>
            <Typography
          sx={{
            whiteSpace: "pre-line",
            color: "#ED6182",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "160%",
            textAlign: "center",
          }}
        >Your test results are being generated....</Typography>
         <Typography
          sx={{
            whiteSpace: "pre-line",
            color: "#000000",
            fontWeight: 300,
            fontSize: "16px",
            lineHeight: "160%",
            textAlign: "center",
          }}
        >Please wait while we analyze your answers.</Typography>
          </div>,
      "",
      "",
      "",
    ];
  }

  return "";
};

const OfflineResults = () => {
  const dispatch = useDispatch();
  const { quizAttemptDetail } = useSelector(
    (state) => state.mytestpackagestate
  );
  const HISTORY = useHistory();
  const { validateUser } = useSelector((state) => state.guestAccessState);

  const { validateTestCode } = useSelector((state) => state.guestAccessState);

  useEffect(() => {
    if (validateUser.userid)
      dispatch(fetchQuizAttempt(validateUser.userid, validateTestCode.quizid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateUser.userid, validateTestCode.quizid]);

  return quizAttemptDetail ? (
    <div>
      <div style={{ marginTop: "20px", marginLeft: "30px" }}>
        <LOGO width="12%" />
      </div>

      <Grid container sx={{ padding: "5% 2% 5% 19%" }}>
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "2px 4px 18px rgba(0, 0, 0, 0.25)",
            borderRadius: "5px",
          }}
        >
          <OfflineHeader />
          <Grid>
          {quizAttemptDetail[0]?.strgrade !== "" && (
            <Typography
              sx={{
                color: "#634699",
                fontWeight: 700,
                fontSize: "16px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
              Your Grade
            </Typography> )}
            <Grid
              display="flex"
              alignItems="center"
              justifyContent="center"
              sx={{ paddingBottom: "20px" }}
            >
              {FilterImage(quizAttemptDetail[0]?.strgrade)[0]}
            </Grid>

            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: "#634699",
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "160%",
                textAlign: "center",
              }}
            >
              {FilterImage(quizAttemptDetail[0]?.strgrade)[1]}
            </Typography>

            <Grid
              display="flex"
              justifyContent="flex-end"
              sx={{ marginRight: "15%" }}
            >
          {quizAttemptDetail[0]?.strgrade !== "" && (

              <Typography
                sx={{
                  color: FilterImage(quizAttemptDetail[0]?.strgrade)[3],
                  fontWeight: 700,
                  fontSize: "14px",
                  lineHeight: "160%",
                  textAlign: "left",
                }}
              >
                -{FilterImage(quizAttemptDetail[0]?.strgrade)[2]}
              </Typography>)}
            </Grid>
            <br />
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                component={Link}
                to={`${validateTestCode?.course}/feedbacks/${validateTestCode?.quizid}`}
                variant="contained"
                sx={{
                  textTransform: "none",
                  width: "150px",
                  height: "30px",
                  backgroundColor: "#634699",
                  border: "1px solid #634699",
                  borderRadius: "5px",
                  minWidth: "4px",
                  align: "center",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#634699",
                    boxShadow: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "150%",
                  }}
                >
                  View Answers
                </Typography>
              </Button>
            </Grid>
            <Grid
              container
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.removeItem("userToken");
                  HISTORY.push("/login/mocktestform");
                  window.location.reload();
                }}
                sx={{
                  marginTop: "12px",
                  textTransform: "none",
                  width: "150px",
                  height: "30px",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #634699",
                  borderRadius: "5px",
                  minWidth: "4px",
                  align: "center",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#FFFFFF",
                    boxShadow: "none",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#634699",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "150%",
                  }}
                >
                  Back to Login
                </Typography>
              </Button>
            </Grid>
            <br />
            <br />
          </Grid>
        </div>{" "}
      </Grid>
    </div>
  ) : (
    ""
  );
};

export default OfflineResults;
