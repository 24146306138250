/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class essayNew.jsx
 * This JSX file is for showing the Essay Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 02 Feb 2023
 */

import React, { useRef, useState, useEffect } from "react";
import { Box, Button, CardMedia, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";
import parse from "html-react-parser";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-classic-extended/build/ckeditor';
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import FileInputNew from "./FileInputNew";
import { formatBytes, urltoFile, getThumbnail, romanNumbers } from "../../../../utils/AppConstants";
import { fetchQuestionStatus } from "../../../../webservice/students/actions/TestAttemptAction";
import { fetchQuizStatus } from "../../../../webservice/students/actions/TestAttemptAction";

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    "& .ck-editor__main > .ck-editor__editable": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "&.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        color: "#634699",
        minHeight: "150.1px",
        borderColor: "#634699",
        border: "0.771363px solid #634699",
        borderRadius: "0px 0px 9.25635px 9.25635px",
        background: "transparent",
        "&.ck .ck-placeholder:before": {
          color: "#634699",
          fontWeight: 400,
          fontStyle: "regular",
          fontSize: "13.8568px",
          lineHeight: "140%",
        },
      },
    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      color: "#634699",
      minHeight: "150.1px",
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "0px 0px 9.25635px 9.25635px",
      background: "transparent",
      "&.ck .ck-placeholder:before": {
        color: "#634699",
        fontWeight: 400,
        fontStyle: "regular",
        fontSize: "13.8568px",
        lineHeight: "140%",
      },
    },
    "& .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      borderColor: "#634699",
      border: "0.771363px solid #634699",
      borderRadius: "9.25635px 9.25635px 0px 0px",
    },
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));

const EssayNew = ({
  attemptidd,
  question,
  selectedAnswer,
  setSelectedAnswer,
  handleChange,
  count,
  show,
  secondLevelIndex,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,
  type,
  quizStatus,
  quiz,  editorData,
  setEditorData
}) => {
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const dispatch = useDispatch();
  const classes = useStyles();
  // const [editorData, setEditorData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialog(true);
    setImageSrc(src);
  };
  const dispatchCalledRef = useRef(false);
  const { userid } = useSelector((state) => state.authState);
  const getLocalStorageKey = () => {
    return `editorData_${question?.questionid || question?.submqid}`;
  };
  
  const getFilesLocalStorageKey = () => {
    return `editorData_${question?.questionid || question?.submqid}_files`;
  };
  
  const saveFilesToLocalStorage = (files) => {
    localStorage.setItem(getFilesLocalStorageKey(), JSON.stringify(files));
  };
  
  const getFilesFromLocalStorage = () => {
    const storedFiles = localStorage.getItem(getFilesLocalStorageKey());
    return storedFiles ? JSON.parse(storedFiles) : [];
  };
  
  // Updated useEffect
  // useEffect(() => {
    
  //     const storedData = localStorage.getItem(getLocalStorageKey());
  //     if (storedData) {
  //       setEditorData(storedData);
  //     }
  //     const storedFiles = getFilesFromLocalStorage();
  //     if (storedFiles) {
  //       setFiles(storedFiles);
  //     }
    
  // }, [ question?.questionid, question?.submqid]);
  // useEffect(() => {
  //   // Check if show is false
  //   if (!show) {
  //     // Remove editor data from local storage based on the current level and question ID
  //     localStorage.removeItem(getLocalStorageKey());
  //     // Clear editor data state
  //     localStorage.removeItem(getFilesLocalStorageKey());
  //     setEditorData("");
  //     setFiles([]);
  //   }
  // }, [show, level, question?.questionid, question?.submqid, thirdLevelIndex]);
  
  
  // Function to save files to local storage
 

  // Function to retrieve files from local storage

  const [fileSizeLimit, setFileSizeLimit] = React.useState({
    name: "",
    value: 0,
  });
  const [files, setFiles] = useState(getFilesFromLocalStorage());
  // const handleThumbnailDelete = (index) => {
  //   const updatedFiles = [...files];
  //   updatedFiles.splice(index, 1);
  //   setFiles(updatedFiles);
  //   saveFilesToLocalStorage(updatedFiles);
  //   let array = [];
  //   if (level === 1) {
  //     array = [...questionStatusResponse[count].uploadedFiles];
  //     array.splice(index, 1);
  //     questionStatusResponse[count].uploadedFiles = array;
  //   } else if (level === 2) {
  //     array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles];
  //     array.splice(index, 1);
  //     questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles = array;
  //   } else if (level === 3) {
  //     array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles];
  //     array.splice(index, 1);
  //     questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles = array;
  //   }

  //   dispatch(fetchQuestionStatus(questionStatusResponse));
  // };
 // Updated useEffect
 useEffect(() => {
  if (show) {
    const storedData = localStorage.getItem(getLocalStorageKey());
    const storedFiles = getFilesFromLocalStorage();

    if (storedData) {
      setEditorData(storedData);
    } else {
      setEditorData("");
    }

    if (storedFiles) {
      setFiles(storedFiles);
    } else {
      setFiles([]);
    }
  }
}, [question?.questionid, question?.submqid, show]);

useEffect(() => {
  // Check if show is false
  if (!show) {
    // Remove editor data from local storage based on the current level and question ID
    localStorage.removeItem(getLocalStorageKey());
    localStorage.removeItem(getFilesLocalStorageKey());

    // Clear editor data state
    // setEditorData("");
    setFiles([]);
  }
}, [show, level, question?.questionid, question?.submqid, thirdLevelIndex]);

// Handle changes to the CKEditor
const HANDLE_CHANGE = (event, editor) => {
  const data = editor.getData();
  setEditorData(data);
  localStorage.setItem(getLocalStorageKey(), data);
  handleChange(data);
};

// Function to handle thumbnail delete
const handleThumbnailDelete = (index) => {
  const updatedFiles = [...files];
  updatedFiles.splice(index, 1);
  setFiles(updatedFiles);
  saveFilesToLocalStorage(updatedFiles);

  let array = [];
  if (level === 1) {
    array = [...questionStatusResponse[count].uploadedFiles];
    array.splice(index, 1);
    questionStatusResponse[count].uploadedFiles = array;
  } else if (level === 2) {
    array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles];
    array.splice(index, 1);
    questionStatusResponse[count].subQuestions[secondLevelIndex].uploadedFiles = array;
  } else if (level === 3) {
    array = [...questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles];
    array.splice(index, 1);
    questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].uploadedFiles = array;
  }

  dispatch(fetchQuestionStatus(questionStatusResponse));
};

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

 useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
    if (matchingAnswer) {
      const updatedAnswer = matchingAnswer.answer;
      if (!editorData && updatedAnswer !== null) {
        dispatch(fetchQuizStatus(userid, quiz.id));
      }
    }
  }, [questionStatusResponse, count, quizStatus, question?.questionid, userid]);

  useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
    if (matchingAnswer && matchingAnswer.answer !== null) {
      const updatedAnswer = matchingAnswer.answer;

      if (level === 1) {
        questionStatusResponse[count].answer = updatedAnswer;
        setEditorData(questionStatusResponse[count]?.answer);
      } else if (level === 2) {
        const answers = quizStatus?.answer;
        if (Array.isArray(answers) && answers.length > 0) {
          answers.forEach((answer) => {
            const clozeAnswers = answer?.clozeanswers;
            if (Array.isArray(clozeAnswers)) {
              clozeAnswers.forEach((clozeItem) => {
                if (clozeItem.subquestionid === question?.submqid) {
                  questionStatusResponse[count].subQuestions[secondLevelIndex].answer = clozeItem.subanswer;
                  setEditorData(questionStatusResponse[count].subQuestions[secondLevelIndex].answer);
                }
              });
            }
          });
        }
      } else if (level === 3) {
        const answers = quizStatus?.answer;
        if (Array.isArray(answers) && answers.length > 0) {
          answers.forEach((answer) => {
            const clozeAnswers = answer?.clozeanswers;
            if (Array.isArray(clozeAnswers)) {
              clozeAnswers.forEach((clozeItem) => {
                if (clozeItem.subquestionid === question?.submqid) {
                  questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer = clozeItem.subanswer;
                  setEditorData(questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer);
                }
              });
            }
          });
        }
      }
    }
    return () => {
      dispatchCalledRef.current = false;
    };
  }, [count, secondLevelIndex, thirdLevelIndex, level, quizStatus, question?.questionid, questionStatusResponse, setEditorData]);

  function SpecialCharactersExtended(editor) {
    // Mathematical Symbols
    editor.plugins.get('SpecialCharacters').addItems('Mathematical', [
        { title: 'alpha', character: 'α' },
        { title: 'beta', character: 'β' },
        { title: 'gamma', character: 'γ' },
        { title: 'delta', character: 'δ' },
        { title: 'theta', character: 'θ' },
        { title: 'epsilon', character: 'ε' },
        { title: 'mu', character: 'μ' },
        { title: 'rho', character: 'ρ' },
        { title: 'sigma', character: 'σ' },
        { title: 'perpendicular', character: '⊥' },
        { title: 'triangle', character: '△' },
        { title: 'subset of', character: '⊂' },
        { title: 'super of', character: '⊃' },
        { title: 'subset of or equal to', character: '⊆' },
        { title: 'super of or equal to', character: '⊇' },
        { title: 'square subset of', character: '⊏' },
        { title: 'square super of', character: '⊐' },
        { title: 'square subset of or equal to', character: '⊑' },
        { title: 'square super of or equal to', character: '⊒' },
        { title: 'therefore', character: '∴' },
        { title: 'because', character: '∵' },
        { title: 'parallel to', character: '∥' },
        { title: 'not parallel to', character: '⊮' },
        { title: 'measured angle', character: '∡' },
        { title: 'spherical angle', character: '∢' },
        { title: 'ohms', character: 'Ω' },
        { title: 'right angle', character: '∟' },
        { title: 'xi', character: 'ξ' },
        
        // Add more mathematical symbols as needed
    ]);

    // Arrows
    editor.plugins.get('SpecialCharacters').addItems('Arrows', [
        { title: 'reversible reaction arrow', character: '⇌' },
        { title: 'lift right arrows', character: '⇄' },
        { title: 'reversible reaction arrow', character: '↔' },
        { title: 'top arrow', character: '↑' },
        { title: 'down arrow', character: '↓' },
        // Add more arrows as needed
    ]);

    // Chemical Symbols
    editor.plugins.get('SpecialCharacters').addItems('Chemical', [
        { title: '(aq)', character: '(aq)' },
        { title: '(s)', character: '(s)' },
        { title: '(g)', character: '(g)' },
        { title: '(l)', character: '(l)' },
        // Add more chemical symbols as needed
    ]);
}


  return (
    <div>
      <Grid container sx={{ marginTop: "1%" }}>
        <Grid item xs={1.5} md={1.5} />
        <Grid item xs={5.2} md={7.3}>
          {/* <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingBottom: "10px",
            }}
          >
            1.
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: "15px",
            }}
          >
            Answer the following questions:-
          </Typography> */}
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontStyle: "regular",
              color: "#634699",
              fontSize: "16px",
              lineHeight: "140%",
              paddingTop:type==="text"?"0px":"10px"
            }}
          >
            {" "}
            {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
          </Typography>{" "}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 400,
              fontStyle: "regular",
              color: "#000000",
              fontSize: "16px",
              lineHeight: "150%",
              paddingBottom: type === "text" ? "0px" : "20px",
            }}
          >
            {parse(question?.questiontext || question?.question || question?.subquestiontext || question?.submqtext, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                  src={domNode.attribs.src}
                  style={{
                    maxWidth: '65%', // Ensures the image fits within the parent container
                    maxHeight: '65%', // Ensures the image fits within the parent container
                    objectFit: 'contain', // Maintains the aspect ratio of the image
                    cursor: 'pointer'
                  }}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
            {/* {parse(reversedText)} */}
          </Typography>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
          {show && question?.defaultmark !== null ? (
  <Typography
    variant="h2"
    sx={{
      fontWeight: 700,
      fontStyle: "regular",
      color: "#634699",
      fontSize: "14px",
      lineHeight: "150%",
      lineHeight: "150%",
      paddingBottom: type === "text" ? "0px" : "20px",
    }}
  >
    ({question?.defaultmark||question?.submqmark ? formatter.format(question.defaultmark||question?.submqmark) : ""} Mark)
  </Typography>) : null}
</div>
          {show ? (
            <React.Fragment>
              <Grid item container className={classes.divContainer} />
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "16px",
                  lineHeight: "140%",
                  paddingBottom: "5px",
                }}
              >
                Answer
              </Typography>
              <Grid item xs={8.8}>
                <div className={classes.richTextEditor}>
      
  <CKEditor
  editor={ClassicEditor}
  data={editorData}
  onChange={(event, editor) => {
    HANDLE_CHANGE(event, editor);
  }}
  config={{
    toolbar: {
      items: [
        "heading",
        "MathType",
        "ChemType",'specialCharacters','subscript', 'superscript',
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",      
        "insertTable",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    language: "en",
  }}
  onReady={(editor) => {
    // Call the function to add extra special characters
    SpecialCharactersExtended(editor);
  }}
/>

                </div>
              </Grid>
              <Grid container sx={{ marginTop: "3%" }}>
                <Grid item xs={9.6} sx={{ display: "flex", flexWrap: "wrap", gap: "10px", }}>
                 {files.map((file, index) => (
                      <div key={index}>
                        <Box
                          sx={{
                            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.13)",
                            marginLeft: "6px",
                            background: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <IconButton
                              position="fixed"
                              sx={{
                                width: "20px",
                                height: "20px",
                                margin: "8px 2px 8px 10px",
                                backgroundColor: "#634699",
                                border: "#634699",
                                color: "white",
                                cursor: "auto",
                                "&:hover": {
                                  backgroundColor: "#634699",
                                },
                              }}
                            >
                              <Typography
                                variant="h2"
                                sx={{
                                  fontWeight: 400,
                                  fontStyle: "regular",
                                  color: "white",
                                  fontSize: "10px",
                                  lineHeight: "140%",
                                  padding: "7px",
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </IconButton>
                            <Typography
                              variant="h2"
                              sx={{
                                fontWeight: 400,
                                fontStyle: "regular",
                                color: "#634699",
                                fontSize: "14px",
                                lineHeight: "140%",
                                padding: "7px",
                              }}
                            >
                              {file?.filename}
                            </Typography>{" "}
                            <IconButton
                              onClick={() => {
                                handleThumbnailDelete(index);
                              }}
                              position="fixed"
                              sx={{
                                width: "20px",
                                height: "20px",
                                margin: "8px",
                                backgroundColor: "transparent",
                                color: "#634699",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              <CloseRoundedIcon sx={{ fontSize: 20, fontWeight: "700" }} />
                            </IconButton>
                          </div>
                        </Box>{" "}
                      </div>
                    ))}
                  
                  <FileInputNew
                    onUpload={(fileSelected) => {
                    const updatedFiles = [...files, ...fileSelected];
                    setFiles(updatedFiles);
                    saveFilesToLocalStorage(updatedFiles);
                   }}
                    fileSizeLimit={fileSizeLimit}
                    attemptidd={attemptidd}
                    count={count}
                    secondLevelIndex={secondLevelIndex}
                    thirdLevelIndex={thirdLevelIndex}
                    level={level}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </div>
  );
};
export default EssayNew;