/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class LoginForm.js
 * This JS file is for designing the login form component
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 * @modifiedBy vidhyadarshini.s@centelon.com
 * @modifiedDate 22 Feb 2022
 */
import classes from "./login.module.css";
import {
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, styled } from "@mui/styles";
import { getUserForgotPassword, loginUser } from "../../../webservice/students/actions/AuthAction";
import { encrypt, decrypt } from "../../../encrypt";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingSpinner from "./LoadingSpinner";
import editicon from "../../../assets/images/ForgotPasswordSelection.svg";
import editicons from "../../../assets/images/ForgotPassEnterdetails.svg";
import mailicons from "../../../assets/images/fpassmailsent.svg";
import invalidicons from "../../../assets/images/fpassInvaliduser.svg";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#fff",
    },
    "&:hover fieldset": {
      borderColor: "#fff",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#fff",
    },
  },
});
const useStyles = makeStyles({
  dialogContainer: {
    "& .MuiDialog-container": {
      backgroundColor: "white !important"
    }
  },
});
const CssTextFields = styled(TextField)({
  "& label.Mui-focused": {
    color: "#634699",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#634699",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#634699",
    },
    "&:hover fieldset": {
      borderColor: "#634699",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#634699",
    },
  },
});
const CustomSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "36.08px",
  height: "18.54px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "transparent" : "transparent",
        opacity: 1,
        border: "1px solid #fff",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "14.53px",
    height: "14.53px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? "transparent" : "transparent",
    opacity: 1,
    border: "1px solid #fff",
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const LOGINFORM = ({ isOnline }) => {
  const CLASSES = useStyles();
  const HISTORY = useHistory();
  const [error, setError] = useState(true);
  const dispatch = useDispatch();
  const [field, setField] = useState({
    email: "",
    password: "",
    isChecked: false,
    showPassword: false,
  });
  const { email, password, isChecked, showPassword } = field;
  const { user } = useSelector((state) => state.authState);
  const { getForgotPassword } = useSelector((state) => state.authState);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const cred = localStorage.getItem("credentials");
  // const [openDialog, setOpenDialog] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameDialogOpen, setUsernameDialogOpen] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [InvaldUserError, setInvaldUserError] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleForgotPasswordClick = () => {
    setConfirmationDialogOpen(true);
  };
  const handleCloseDialog = () => {
    // setOpenDialog(false);
  };
  const handleGetPassword = () => {
    setLoading(true); // Start loading spinner
    dispatch(getUserForgotPassword(username));
  };
  useEffect(() => {
    if (getForgotPassword) {
      if (getForgotPassword.mailsent === false && getForgotPassword.message === "Invalid User") {
        setInvaldUserError(true);
      } else if (getForgotPassword.mailsent === true && getForgotPassword.message === "updated") {
        setMailSent(true);
      }
    }
    setLoading(false); // Stop loading spinner
  }, [getForgotPassword]);
  useEffect(() => {
    if (cred) {
      const credentials = JSON.parse(decrypt(cred));
      setField(credentials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("userToken", encrypt(user));
      HISTORY.push("/dashboard");
    } else {
      setError(!error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isChecked && email !== "") {
      localStorage.setItem(
        "credentials",
        JSON.stringify(encrypt({ email, password, isChecked }))
      );
    }
    dispatch(loginUser(email, password));
    setField({ email: "", password: "", isChecked: false });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setField({ ...field, [name]: value });
  };

  const handleCheckbox = (e) => {
    setField({ ...field, isChecked: e.target.checked });
    if (!e.target.checked) {
      localStorage.clear();
      setField({ email: "", password: "", isChecked: false });
    }
  };

  const handleClickShowPassword = () => {
    setField({
      ...field,
      showPassword: !field.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Grid container>
      <form onSubmit={handleSubmit}>
        <Grid item container direction="column" xs={12}>
          <Grid item>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "35px",
                lineHeight: "130%",
                color: "#FFFFFF",
              }}
            >
              Welcome Back
            </Typography>
          </Grid>
          <Grid>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "140%",
                color: "#C4C4C4",
                marginBottom: "40px",
              }}
            >
              Enter your username and password to sign in
            </Typography>
          </Grid>

          {error && <p style={{ color: "red" }}>Invalid email/password</p>}
          <Grid item>
            <FormControl>
              <CssTextField
                sx={{
                  input: { color: "#fff" },
                  width: "25vw",
                  marginBottom: "15px",
                  ["@media (max-width:700px)"]: { width: "68vw" },
                }}
                InputLabelProps={{ style: { color: "#A0AEC0" } }}
                label="Your Username"
                required
                fullWidth
                id="inputEmail"
                name="email"
                onChange={handleChange}
                value={email}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <CssTextField
                sx={{
                  input: { color: "#fff" },
                  width: "25vw",
                  marginBottom: "15px",
                  ["@media (max-width:700px)"]: { width: "68vw" },
                }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                required
                onChange={handleChange}
                InputLabelProps={{ style: { color: "#A0AEC0" } }}
                label="Your Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                         <VisibilityOff style={{ color: "#fff" }} />
                        ) : (
                           <Visibility style={{ color: "#fff" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Grid item xs={6}>
              <FormControlLabel
                checked={isChecked}
                onChange={handleCheckbox}
                control={<CustomSwitch sx={{ m: 1 }} />}
                label={
                  <Typography
                    style={{
                      fontWeight: 400,
                      fontSize: "12.026px",
                      lineHeight: "150%",
                      color: "#FFFFFF",
                    }}
                  >
                    Remember me
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "12.026px",
                  lineHeight: "150%",
                  color: "#FFFFFF",
                  textAlign: 'right',
                  textDecoration: 'underline',
                  cursor: "pointer", // Add this line to make it clickable
                }}
                onClick={handleForgotPasswordClick} // Add this line
              >
                Forgot Password?
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: "#FFFFFF",
                color: "#634699",
                textTransform: "none",
                borderRadius: "5px",
              }}
              type="submit"
              variant="contained"
              className={classes.btnstyle}
            >
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "18px",
                  lineHeight: "150%",
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",

                  color: "#634699",
                }}
              >
                Login{" "}
              </Typography>
            </Button>
          </Grid>
          <br />
          <Grid item textAlign="center">
            <Typography className={classes.regtext}>
              Don 't have an account? &nbsp;
              <Link
                to="/signup"
                style={{ textDecoration: "none", color: "#FFFFFF" }}
              >
                <b>Sign Up</b>
              </Link>
            </Typography>
          </Grid>
          <Dialog open={confirmationDialogOpen} PaperProps={{
            style: {
              background: "#FFFFFF",
              // boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
              borderRadius: "5px",
              padding: "3%",
              maxWidth: "1000px",
              // maxHeight:"550px"

            },
          }} sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
            <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>
              <Grid container>
                <Grid item xs={6} >
                  <img src={editicon} alt="edit" width="300px" height="300px" />
                </Grid>
                <Grid item xs={6} >
                  <Grid
                    item
                    sx={{ paddingTop: "100px", paddingBottom: "20px", paddingLeft: "50px" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "30px",
                        lineHeight: "79.3px",
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                        color: "#634699",
                      }}
                    >
                      Forgot Password?
                    </Typography>
                    <Button
                      onClick={() => setConfirmationDialogOpen(false)}
                      sx={{
                        background: "#FFFFFF",
                        color: "#634699",
                        borderRadius: "5px",
                        marginRight: "20px",
                        textTransform: "none",
                        border: "1px solid #634699",
                        fontWeight: 700,
                        fontSize: "14px",
                        boxShadow: "none",
                        padding: "3px 45px 3px 45px",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#634699",
                          boxShadow: "none",
                        },
                      }}
                    >
                      No
                    </Button>
                    <Button
                      // onClick={() => setConfirmationDialogOpen(false)}
                      onClick={() => {
                        setConfirmationDialogOpen(false);
                        setUsernameDialogOpen(true);
                      }}
                      sx={{
                        background: "#634699",
                        color: "#FFFFFF",
                        borderRadius: "5px",
                        textTransform: "none",
                        boxShadow: "none",
                        padding: "3px 45px 3px 45px",
                        fontWeight: 700,
                        fontSize: "14px",
                        "&:hover": {
                          backgroundColor: "#634699",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Yes
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Dialog>
          {usernameDialogOpen && (
            <Dialog
              open={usernameDialogOpen}
              PaperProps={{
                style: {
                  background: "#FFFFFF",
                  // boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
                  borderRadius: "5px",
                  padding: "3%",
                  maxWidth: "1000px",
                  // maxHeight:"550px"

                },
              }} sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
              <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>
                <Grid container>
                  <Grid item xs={6} >
                    <img src={editicons} alt="edit" width="300px" height="313px" />
                  </Grid>
                  <Grid item xs={6} >
                    <Grid
                      item
                      sx={{ padding: "60px 40px 75px 0px" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "30px",
                          lineHeight: "59.3px",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "center",
                          color: "#634699",
                        }}
                      >
                        Enter Details
                      </Typography>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "19.64px",
                          opacity: 0.34, paddingBottom: "10px"
                        }}
                      >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                      </Typography>
                      <Grid><Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "16px",
                        }}
                      >
                        {" "}
                        Enter Username*
                      </Typography></Grid>
                      <CssTextFields
                        variant="outlined"
                        fullWidth
                        required
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        size="small"
                        value={username} // Add this line to capture the username
                        onChange={(e) => setUsername(e.target.value)} // Add this line to update the username state


                      /><br /><br />
                      <Grid sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                          {loading && <LoadingSpinner />} {/* Show loading spinner while loading */}
                          {/* Rest of your JSX */}
                        </div>
                        {!loading && (
                <>  
                  <Button
                   onClick={()=>setUsernameDialogOpen(false)} // Add this line for cancel action
                      sx={{
                        background: "#FFFFFF",
                        color: "#634699",
                        borderRadius: "5px",
                        marginRight: "20px",
                        textTransform: "none",
                        border: "1px solid #634699",
                        fontWeight: 700,
                        fontSize: "11.6px",
                        boxShadow: "none",
                        padding: "5px 25px 5px 25px",
                        "&:hover": {
                          backgroundColor: "#FFFFFF",
                          color: "#634699",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                          <Button
                            onClick={handleGetPassword}
                            sx={{
                              background: !username ? "#e7e7e7" : "#634699",
                              color: "#FFFFFF",
                              borderRadius: "3px",
                              textTransform: "none",
                              boxShadow: "none",
                              padding: "5px 25px 5px 25px",
                              fontWeight: 700,
                              fontSize: "11.6px",
                              "&:hover": {
                                backgroundColor: !username ? "#FF0000" : "#634699",
                                boxShadow: "none",
                              },
                            }}
                            disabled={!username}
                          >
                            Send
                          </Button>
                </>
                        )}
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Dialog>
          )}
          {mailSent && (
            <Dialog open={mailSent} onClose={() => setMailSent(false)} PaperProps={{
              style: {
                background: "#FFFFFF",
                // boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
                borderRadius: "5px",
                padding: "3%",
                maxWidth: "1000px",
                // maxHeight:"550px"

              },
            }} sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
              <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>

                <div style={{ display: "flex", justifyContent: "center" }}> <img src={mailicons} alt="edit" width="225.21px" height="165px" /><br /></div>
                <br /><Typography
                  sx={{
                    display: "flex", justifyContent: "center",
                    fontWeight: "700",
                    fontSize: "35px",
                    lineHeight: "15.3px",
                    // display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#634699",
                  }}
                >
                  Mail
                </Typography><span style={{
                  display: "block", justifyContent: "center",
                  fontWeight: "700",
                  fontSize: "35px",
                  lineHeight: "47.3px",
                  // display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#37AB97",
                }}
                >
                  Sent !</span><span style={{
                    display: "block", justifyContent: "center",
                    fontWeight: "700",
                    fontSize: "14.03px",
                    lineHeight: "19.64px",
                    // display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#634699",
                    opacity: 0.7
                  }}
                  >
                  Please check your registered email for password reset instructions.
                </span>

                <br />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    // onClick={() => setConfirmationDialogOpen(false)}
                    onClick={() => {
                      localStorage.removeItem("userToken")
                      window.location.reload();
                      HISTORY.push("/login")
                    }}
                    sx={{
                      background: "#634699",
                      color: "#FFFFFF",
                      borderRadius: "3px",
                      textTransform: "none",
                      boxShadow: "none",
                      padding: "5px 25px 5px 25px",
                      fontWeight: 700,
                      fontSize: "11.6px",
                      "&:hover": {
                        backgroundColor: "#634699",
                        boxShadow: "none",
                      },
                    }}
                  >
                    OK
                  </Button></div>
              </Card>
              {/* <Button onClick={() => setMailSent(false)}>OK</Button> */}

            </Dialog>
          )}
          <Dialog open={InvaldUserError} PaperProps={{
            style: {
              background: "#FFFFFF",
              // boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
              borderRadius: "5px",
              padding: "3%",
              maxWidth: "1000px",
              // maxHeight:"550px"

            },
          }} sx={{ backgroundColor: "rgba(255, 255, 255, 1)", }} className={CLASSES.dialogContainer}>
            <Card sx={{ backgroundColor: "#FFFFFF", width: "800px", height: "330px", }}>

              <div style={{ display: "flex", justifyContent: "center" }}> <img src={invalidicons} alt="edit" width="225.21px" height="165px" /><br /></div>
              <br /><Typography
                sx={{
                  display: "flex", justifyContent: "center",
                  fontWeight: "700",
                  fontSize: "35px",
                  lineHeight: "49.3px",
                  // display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  color: "#634699",
                }}
              >
                Uh Oh!
              </Typography><span style={{
                display: "block", justifyContent: "center",
                fontWeight: "700",
                fontSize: "14.03px",
                lineHeight: "19.64px",
                // display: "flex",
                alignItems: "center",
                textAlign: "center",
                color: "#634699",
                opacity: 0.7
              }}
              >
                Sorry, but the user credentials you provided are invalid.
              </span>

              <br />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  // onClick={() => setConfirmationDialogOpen(false)}
                  onClick={() => {
                    setInvaldUserError(false)
                    setUsernameDialogOpen(true);
                  }}
                  sx={{
                    background: "#634699",
                    color: "#FFFFFF",
                    borderRadius: "3px",
                    textTransform: "none",
                    boxShadow: "none",
                    padding: "5px 25px 5px 25px",
                    fontWeight: 700,
                    fontSize: "11.6px",
                    "&:hover": {
                      backgroundColor: "#634699",
                      boxShadow: "none",
                    },
                  }}
                >
                  Go Back
                </Button></div>
            </Card>
          </Dialog>
        </Grid>
      </form>
    </Grid>
  );
};
export default LOGINFORM;
