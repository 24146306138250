/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class multiChoiceNew.jsx
 * This JSX file is for showing the MultiChoice Question view
 *
 * @author vidhyadarshini.s@centelon.com
 * @since 01 Feb 2023
 */
import React, { useState, useRef } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Card, Grid, Typography, Dialog, DialogContent, CardMedia } from "@mui/material";
import parse from "html-react-parser";
import { makeStyles } from "@mui/styles";
import { McqIcon } from "../../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { romanNumbers } from "../../../../utils/AppConstants";
import { fetchQuizStatus } from "../../../../webservice/students/actions/TestAttemptAction";
const USE_STYLES = makeStyles(() => ({
  icon: {
    height: "10px",
  },
  divContainer: {
    borderTop: "2px dashed #634699",
    height: "25px",
  },
}));
const MultiChoiceNew = ({
  selectedAnswer,
  setSelectedAnswer,
  question,
  handleChange,
  count,
  show,
  secondLevelIndex,
  type,
  thirdLevelIndex,
  setthirdLevelIndex,
  level,quizStatus,quiz
  
}) => {
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { questionStatusResponse } = useSelector((state) => state.testAttemptState);
  const { userid } = useSelector((state) => state.authState);
  useEffect(() => {
    if (level === 2) {
      setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.answer);
    } else if (level === 1) {
      setSelectedAnswer(questionStatusResponse[count]?.answer);
    } else if (level === 3) {
      setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, secondLevelIndex, thirdLevelIndex, level]);
  useEffect(() => {
    const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);

    if (matchingAnswer) {
        // If there is a matching answer in quizStatus, update questionStatusResponse
        const updatedAnswer = matchingAnswer.answer;
        
        if (selectedAnswer !== updatedAnswer) {
            dispatch(fetchQuizStatus(userid, quiz.id));
        }
    }
}, [questionStatusResponse, count, quizStatus, question?.questionid, userid]);

 const dispatchCalledRef = useRef(false);

 useEffect(() => {
    
  const matchingAnswer = quizStatus?.answer?.find(item => item.questionid === question?.questionid);
  if (matchingAnswer) {
    // If there is a matching answer in quizStatus, update questionStatusResponse
    const updatedAnswer = matchingAnswer.answer ;

    if (level === 1) {
      questionStatusResponse[count].answer = updatedAnswer;
      setSelectedAnswer(questionStatusResponse[count]?.answer);
    
    } 
  }  else {
     
if (level === 2) {
  
 const answers = quizStatus?.answer;
  
  // Check if answers is present and is an array
  if (Array.isArray(answers) && answers.length > 0) {
    // Loop through each item in answers and check if clozeanswers is present
    answers.forEach((answer, index) => {
      // Access the clozeanswers array from each answer if available
      const clozeAnswers = answer?.clozeanswers;
  
      // Check if clozeAnswers is present and is an array
      if (Array.isArray(clozeAnswers)) {
        if (clozeAnswers.length > 0) {
          // Loop through each item in clozeAnswers
          clozeAnswers.forEach((clozeItem, clozeIndex) => {
            // Check if clozeItem.subquestionid matches question?.submqid
            if (clozeItem.subquestionid === question?.submqid) {
              // Log the subanswer
  
              // Save the subanswer to selectedAnswer and questionStatusResponse
              questionStatusResponse[count].subQuestions[secondLevelIndex].answer = clozeItem.subanswer||"";
              setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.answer);
              if (selectedAnswer !== clozeItem.subanswer && !dispatchCalledRef.current) {
                // Set the flag to true to indicate that dispatch has been called
                dispatchCalledRef.current = true;
            
                // Dispatch the action
                dispatch(fetchQuizStatus(userid, quiz.id));
              }
            }
          });
        }
      }
    });
  }
}

       else if (level === 3) {
  
  // questionStatusResponse[count].subQuestions[secondLevelIndex].answer = updatedAnswer;
  // setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer);
  const answers = quizStatus?.answer;
  
  // Check if answers is present and is an array
  if (Array.isArray(answers) && answers.length > 0) {
    // Loop through each item in answers and check if clozeanswers is present
    answers.forEach((answer, index) => {
      // Access the clozeanswers array from each answer if available
      const clozeAnswers = answer?.clozeanswers;
  
      // Check if clozeAnswers is present and is an array
      if (Array.isArray(clozeAnswers)) {
        if (clozeAnswers.length > 0) {
          // Loop through each item in clozeAnswers
          clozeAnswers.forEach((clozeItem, clozeIndex) => {
            // Check if clozeItem.subquestionid matches question?.submqid
            if (clozeItem.subquestionid === question?.submqid) {
              // Log the subanswer
  
              // Save the subanswer to selectedAnswer and questionStatusResponse
              // setSelectedAnswer(clozeItem.subanswer);
              questionStatusResponse[count].subQuestions[secondLevelIndex].subQuestions[thirdLevelIndex].answer = clozeItem.subanswer||[];
              setSelectedAnswer(questionStatusResponse[count]?.subQuestions[secondLevelIndex]?.subQuestions[thirdLevelIndex]?.answer);
              if (selectedAnswer !== clozeItem.subanswer && !dispatchCalledRef.current) {
                // Set the flag to true to indicate that dispatch has been called
                dispatchCalledRef.current = true;
            
                // Dispatch the action
                dispatch(fetchQuizStatus(userid, quiz.id));
              }
            }
          });
        }
      }
    });
  }
      }
    }
    return () => {
      dispatchCalledRef.current = false;
    };
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [count, secondLevelIndex, thirdLevelIndex, level, quizStatus, question?.questionid, questionStatusResponse, setSelectedAnswer]);

  
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialog(true);
    setImageSrc(src);
  };

  return (
    <Grid container sx={{ marginTop: "1%" }}>
      <Grid item xs={1.5} md={1.5} />
      <Grid item xs={5.2} md={7.3}>
        {/* <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingBottom: "10px",
          }}
        >
          1.
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontWeight: 400,
            fontStyle: "regular",
            color: "#000000",
            fontSize: "16px",
            lineHeight: "150%",
            paddingBottom: "15px",
          }}
        >
          Choose the correct Answer:
        </Typography> */}
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingTop:type==="text"?"0px":"10px"
          }}
        >
          {" "}
          {type === "text" ? `${romanNumbers[thirdLevelIndex]})` : question?.slot}
        </Typography>{" "}
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <Typography
    variant="h2"
    sx={{
      fontWeight: 400,
      fontStyle: "regular",
      color: "#000000",
      fontSize: "16px",
      lineHeight: "150%",
      paddingBottom: type === "text" ? "0px" : "8px",
    }}
  >
    {parse(question?.questiontext || question?.question || question?.subquestiontext || question?.submqtext, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                  src={domNode.attribs.src}
                  style={{
                    maxWidth: '65%', // Ensures the image fits within the parent container
                    maxHeight: '65%', // Ensures the image fits within the parent container
                    objectFit: 'contain', // Maintains the aspect ratio of the image
                    cursor: 'pointer'
                  }}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
      </Typography>

     

      {/* Dialog to display larger image */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
  {show && question?.defaultmark !== null ? (
  <Typography
    variant="h2"
    sx={{
      fontWeight: 700,
      fontStyle: "regular",
      color: "#634699",
      fontSize: "14px",
      lineHeight: "150%",
      paddingBottom: type === "text" ? "0px" : "8px",
    }}
  >
    ({question?.defaultmark||question?.submqmark ? formatter.format(question.defaultmark||question?.submqmark) : ""} Mark)
   </Typography>) : null}
</div>
        <Grid item container className={CLASSES.divContainer} />
        <Typography
          variant="h2"
          sx={{
            fontWeight: 700,
            fontStyle: "regular",
            color: "#634699",
            fontSize: "16px",
            lineHeight: "140%",
            paddingBottom: "5px",
          }}
        >
          Options:
        </Typography>
        {(question?.answers || question?.mcqanswers || question?.mcqoptions).map((option, index) => {
          const isImage = (option && option.answer && typeof option.answer === 'string' && (option.answer.includes("<img") || option.answer.includes("<img")));
          const isSelected =
          selectedAnswer == option.id ||
          selectedAnswer === index ||
          selectedAnswer === index.toString();
          return parseInt(question.single) === 1 || !question.single ? (
            <Box
              key={index}
              // onClick={() => {
              //   handleChange(index);
              // }}
              onClick={() => handleChange(index,option.id)}
              sx={{
                background: isSelected ? "#634699" : "#FFFFFF",
                boxShadow: !show
                  ? "none"
                  : isSelected
                  ? "1px 7px 25px #634699"
                  : "1px 7px 25px rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                padding: "0% 2% 0% 2%",
                fontWeight: "400",
                color: isSelected ? "#FFFFFF" : "#000000",
                width: isImage ? "auto" : "250px",
                margin: "1% 0% 0% 0%",
                display: "flex",
                alignItems: "center",
                cursor: !show ? "auto" : "pointer",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: !show ? "none" : "#634699",
                  color: !show ? "none" : "#FFFFFF",
                },
              }}
            >
              <McqIcon
                sx={{
                  stroke: isSelected ? "#FFFFFF" : "#634699",
                }}
                className={CLASSES.icon}
              />
              <Box>{parse(option.answer || option)}</Box>
            </Box>
          ) : (
            <Box
              key={index}
              onClick={() => {
                handleChange(index);
              }}
              sx={{
                background: selectedAnswer.includes(index) ? "#634699" : "#FFFFFF",
                boxShadow: !show
                  ? "none"
                  : selectedAnswer.includes(index)
                  ? "1px 7px 25px #634699"
                  : "1px 7px 25px rgba(0, 0, 0, 0.11)",
                borderRadius: "5px",
                padding: "1%",
                fontWeight: "400",
                color: selectedAnswer.includes(index) ? "#FFFFFF" : "#000000",
                width: isImage ? "auto" : "250px",
                margin: "1% 0% 0% 0%",
                display: "flex",
                alignItems: "center",
                cursor: !show ? "auto" : "pointer",
                fontSize: "14px",
                "&:hover": {
                  backgroundColor: !show ? "none" : "#634699",
                  color: !show ? "none" : "#FFFFFF",
                },
              }}
            >
              <McqIcon
                sx={{
                  stroke:  selectedAnswer.includes(index) ? "#FFFFFF" : "#634699",
                }}
                className={CLASSES.icon}
              />
              <Box>{parse(option.answer || option)}</Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};
export default MultiChoiceNew;
