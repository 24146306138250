import React from "react";
import { Fragment, useState } from "react";
import parse from "html-react-parser";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  Grid,
  Button,
  Typography,
  Card,
  CardContent,
  Pagination,
  FormHelperText,
  Collapse,
  IconButton,
  ListItem,
  List,
  Dialog,
  DialogContent,
  CardMedia,
} from "@mui/material";
import FeedbackQuestion from "./feedbackQuestion";
import { romanNumbers } from "../../../../utils/AppConstants";
import StarBorderPurple500OutlinedIcon from "@mui/icons-material/StarBorderPurple500Outlined";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchQuizAttempt } from "../../../../webservice/students/actions/MyTestPackageAction";
import { makeStyles } from "@mui/styles";
import { getThumbnail } from "../../../../utils/AppConstants";
import {
  ExpandFilledIcon,
  ExpandIcon,
  Scheduleiconbtn,
} from "../../../../assets/icons";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import MultiChoiceNew from "./multiChoiceNew";
import TrueFalseNew from "./TrueFalseNew";
import MatchNew from "./MatchNew";
import ShortAnswerNew from "./ShortAnswerNew";
import EssayNew from "./essayNew";
// import SubquestionNew from "./SubquestionNew";
import OfflineHeader from "../../../../layouts/main/header/OfflineHeader";
import LOGO from "../../../main/ui_component/logo";
import MultiAnswer from "./MultiAnswer";
import flag from "./../../../../assets/images/flag.png";

const USE_STYLES = makeStyles({
  pagination: {
    "& .MuiButtonBase-root": {
      border: "transparent",
      color: "#000000",
      fontWeight: "700",
      borderRadius: "5px",
    },
    "& .Mui-selected": {
      backgroundColor: "#634699",
      color: "#fff",
      "&:hover": { backgroundColor: "#634699" },
    },
  },
});

const OfflineQuestionFeedbacknew = () => {
  const classes = USE_STYLES();
  let history = useHistory();
  const params = useParams();
  const quizid = params.quizid;
  const questionattemtid = params.questionname;
  const [open, setOpen] = useState(false);
  const [expandOpen, setExpandOpen] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [count, setCount] = useState(0);
  const { quizAttemptDetail } = useSelector(
    (state) => state.mytestpackagestate
  );
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  useEffect(
    () => {
      if (userid) dispatch(fetchQuizAttempt(userid, quizid));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [userid],
    quizid
  );

  const [page, setPage] = useState(1);
  useEffect(() => {
    setPage(parseInt(questionattemtid));
  }, [questionattemtid]);

  const HANDLECHANGE = (event, value) => {
    setPage(value);
  };

  const HANDLEPREVCLICK = () => {
    setPage(page - 1);
  };
  const HANDLENEXTCLICK = () => {
    if (page === quizAttemptDetail[0].questionlist.length) {
      setOpenDialog(true);
    } else {
    setPage(page + 1);}
  };
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [openDialogs, setOpenDialogs] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    setOpenDialogs(true);
    setImageSrc(src);
  };
  return quizAttemptDetail ? (
    <div>
    <div style={{ marginTop: "20px", marginLeft: "30px" }}>
    <LOGO width="12%" />
  </div>

  <Grid container sx={{ padding: "5% 2% 5% 19%" }}>
    <div
      style={{
        // background: "#FFFFFF",
        // boxShadow: "2px 4px 18px rgba(0, 0, 0, 0.25)",
        borderRadius: "5px",
        marginRight: "187.5px"
      }}
    >
    {/* <Grid container spacing={2}> */}
      <OfflineHeader />

      <Fragment>
      <div style={{padding:"10px 10px 10px 0px",margin:"20px"}}>
          <Grid item container xs={12}>
            {/* <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: "40px",
                  lineHeight: "56px",
                }}
              >
                {quizAttemptDetail[0]?.sectionname}
              </Typography>
            </Grid> */}
          </Grid>
          <Fragment>
            <Grid item container xs={12} spacing={1}>
            <Grid item xs={12}>
            <Button
              sx={{
                color: "#634699",
                fontWeight: "400",
                textTransform: "none",
                fontSize:"14px",
                lineHeight:"140%",
                textAlign:"left",
                padding:"0px",
                minWidth:0

              }}
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Grid>
              <Grid item xs={12} md={8} sm={12}>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontStyle: "regular",
                    color: "#634699",
                    fontSize: 24,
                  }}
                >
                  Question{" "}
                  {quizAttemptDetail[0].questionlist[page - 1].questionorder}
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={0.5}>
                <StarBorderPurple500OutlinedIcon />
              </Grid> */}
              {/* <Grid
                item
                xs={12}
                md={3.5}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  component={Link}
                  to="/schedule"
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    backgroundColor: "#634699",
                    "&:hover": {
                      backgroundColor: "#634699",
                    },
                  }}
                >
                  <Scheduleiconbtn sx={{ fontSize: 22 }} />
                  &nbsp;Schedule meeting
                </Button>
              </Grid> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
            {quizAttemptDetail[0].questionlist[page - 1]?.qtype !== "multiquestion" ? (
              <>
              <Card
                sx={{
                  paddingBottom: "25px",
                  width: "100%",
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={11}>
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "multichoice" ? (
                        <MultiChoiceNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          selectedAnswer={selectedAnswer}
                          setSelectedAnswer={setSelectedAnswer}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "truefalse" ? (
                        <TrueFalseNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          selectedAnswer={selectedAnswer}
                          setSelectedAnswer={setSelectedAnswer}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "match" ? (
                        <MatchNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          count={count}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "shortanswer" ? (
                        <ShortAnswerNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype ===
                      "essay" ? (
                        <EssayNew
                          question={quizAttemptDetail[0].questionlist[page - 1]}
                          show={false}
                        />
                      ) : (
                        ""
                      )}
                      {quizAttemptDetail[0].questionlist[page - 1]?.qtype !== "multiquestion" ? (
                      <Grid item xs={12} md={1} sm={12} display="flex" justifyContent="flex-end">
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: 40,
                            lineHeight: "33px",
                          }}
                        >
                          {/* {quizAttemptDetail[0].questionlist[page - 1].mark > "0"
                            ? `+${formatter.format(quizAttemptDetail[0].questionlist[page - 1].mark)}`
                            : formatter.format(quizAttemptDetail[0].questionlist[page - 1].mark)}
                          <span
                            style={{
                              fontWeight: 400,
                              fontStyle: "regular",
                              color: "#634699",
                              fontSize: 15,
                              lineHeight: "33px",
                            }}
                          >
                            Points
                          </span> */}
                        </Typography>
                      </Grid>
                    ) : (
                      ""
                    )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={1}
                      sm={12}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontStyle: "regular",
                          color: "#634699",
                          fontSize: 40,
                          lineHeight: "33px",
                        }}
                      >
                        {quizAttemptDetail[0].questionlist[page - 1].mark > "0"
                          ? `+${formatter.format(
                              quizAttemptDetail[0].questionlist[page - 1].mark
                            )}`
                          : formatter.format(
                              quizAttemptDetail[0].questionlist[page - 1].mark
                            )}
                        <span
                          style={{
                            fontWeight: 400,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: 15,
                            lineHeight: "33px",
                          }}
                        >
                          Points
                        </span>
                      </Typography>
                    </Grid>
                    
                  </Grid>
                </CardContent>
              </Card> {quizAttemptDetail[0].questionlist[page - 1]?.comment?(
           <Grid container >
           <Grid item xs={12}>
             <Card
               sx={{
                 marginTop: "10px",
                 paddingLeft: "16px",
                 background: expandOpen ? "#634699" : "#FFFFFF",
               }}
             >
               <Grid container>
                 <Grid item xs={1}>
                   <IconButton
                     onClick={() => setExpandOpen(!expandOpen)}
                     aria-label="expand"
                     size="small"
                     sx={{
                       transform: !expandOpen ? "rotate(0deg)" : "rotate(90deg)",
                       marginLeft: "auto",
                       marginTop: "10px",
                     }}
                   >
                     {expandOpen ? <ExpandFilledIcon sx={{ fontSize: 18 }} /> : <ExpandIcon sx={{ fontSize: 18 }} />}
                   </IconButton>
                 </Grid>
                 <Grid item xs={11}>
                   <Typography
                   variant="h2"
                     sx={{
                       fontWeight: 700,
                       fontStyle: "regular",
                       color: expandOpen ? "#FFFFFF" : "#634699",
                       fontSize: "16px",
                       lineHeight: "140%",
                       padding: "10px",
                     }}
                   >
                     Feedback
                   </Typography>
                 </Grid>
               </Grid>
             </Card>
           </Grid>
           <Grid item xs={12}>
           
             <Collapse in={expandOpen} timeout="auto" unmountOnExit>
               {quizAttemptDetail[0].questionlist[page - 1]?.comment ? (
                 <Grid item xs={12}>
                   <Typography
                   variant="h2"
                     sx={{
                       fontWeight: 400,
                       fontStyle: "regular",
                       color: "#000",
                       fontSize: 16,
                       lineHeight: "22px",
                       padding: "8px",
                       marginLeft: "30px",
                     }}
                   >
                     {parse(quizAttemptDetail[0].questionlist[page - 1]?.comment)}
                   </Typography>
                 </Grid>
               ) : null}
             </Collapse>
           </Grid>
         </Grid>
        ):null}</>) : null} 
            </Grid>
            {quizAttemptDetail[0].questionlist[page - 1]?.qtype !== "multiquestion" ? (
              <Grid container paddingLeft="16px" >
              <Grid item xs={12} md={12}><FeedbackQuestion count={count} page={page} quizAttemptDetail={quizAttemptDetail} /></Grid></Grid>
          
            ) : (
              <>
                <Grid item xs={12} sm={12} md={12}>
                  {/* <Card
                sx={{
                  paddingBottom: "25px",
                  width: "100%",
                }}
              >
                <CardContent> */}
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Card
                        sx={{
                          // paddingBottom: "25px",
                          width: "100%",
                        }}
                      >
                        <CardContent>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {page})
                            {parse(quizAttemptDetail[0].questionlist[page - 1]?.clozemainquestiontext, {
          replace: (domNode) => {
            if (domNode.type === "tag" && domNode.name === "img") {
              // If image tag found, add click event to open dialog with larger image
              return (
                <img
                  src={domNode.attribs.src}
                  width="100%" // Set your desired fixed width
                  height="100%" // Set your desired fixed height
                  style={{cursor:"pointer"}}
                  alt=""
                  onClick={() => handleImageClick(domNode.attribs.src)}
                />
              );
            }
          },
        })}
                            <Dialog open={openDialogs} onClose={() => setOpenDialogs(false)}>
        <DialogContent>
          <CardMedia component="img" image={imageSrc} />
        </DialogContent>
      </Dialog>
                            <Grid  display="flex" justifyContent="flex-end">
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontStyle: "regular",
                            color: "#634699",
                            fontSize: 40,
                            lineHeight: "33px",
                          }}
                        >
                          {quizAttemptDetail[0].questionlist[page - 1].mark > "0"
                            ? `+${formatter.format(quizAttemptDetail[0].questionlist[page - 1].mark)}`
                            : formatter.format(quizAttemptDetail[0].questionlist[page - 1].mark)}
                          <span
                            style={{
                              fontWeight: 400,
                              fontStyle: "regular",
                              color: "#634699",
                              fontSize: 15,
                              lineHeight: "33px",
                            }}
                          >
                            Points
                          </span>
                        </Typography>
                      </Grid></div>
                        </CardContent>
                      </Card>
                      {quizAttemptDetail[0].questionlist[page - 1]?.comment?(
           <Grid container >
           <Grid item xs={12}>
             <Card
               sx={{
                 marginTop: "10px",
                 paddingLeft: "16px",
                 background: expandOpen ? "#634699" : "#FFFFFF",
               }}
             >
               <Grid container>
                 <Grid item xs={1}>
                   <IconButton
                     onClick={() => setExpandOpen(!expandOpen)}
                     aria-label="expand"
                     size="small"
                     sx={{
                       transform: !expandOpen ? "rotate(0deg)" : "rotate(90deg)",
                       marginLeft: "auto",
                       marginTop: "10px",
                     }}
                   >
                     {expandOpen ? <ExpandFilledIcon sx={{ fontSize: 18 }} /> : <ExpandIcon sx={{ fontSize: 18 }} />}
                   </IconButton>
                 </Grid>
                 <Grid item xs={11}>
                   <Typography
                   variant="h2"
                     sx={{
                       fontWeight: 700,
                       fontStyle: "regular",
                       color: expandOpen ? "#FFFFFF" : "#634699",
                       fontSize: "16px",
                       lineHeight: "140%",
                       padding: "10px",
                     }}
                   >
                     Feedback
                   </Typography>
                 </Grid>
               </Grid>
             </Card>
           </Grid>
           <Grid item xs={12}>
           
             <Collapse in={expandOpen} timeout="auto" unmountOnExit>
               {quizAttemptDetail[0].questionlist[page - 1]?.comment ? (
                 <Grid item xs={12}>
                   <Typography
                   variant="h2"
                     sx={{
                       fontWeight: 400,
                       fontStyle: "regular",
                       color: "#000",
                       fontSize: 16,
                       lineHeight: "22px",
                       padding: "8px",
                       marginLeft: "30px",
                     }}
                   >
                     {parse(quizAttemptDetail[0].questionlist[page - 1]?.comment)}
                   </Typography>
                 </Grid>
               ) : null}
             </Collapse>
           </Grid>
         </Grid>
        ):null}
                      {quizAttemptDetail[0].questionlist[page - 1].mqsubquestions.map((subQuestion, index) => {
                        return (
                          <div key={index}>
                           
                            {subQuestion.submqqtype === "text" ? (
                              <>
                                <Card
                                  sx={{
                                    // paddingBottom: "25px",
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <div>{page}</div>
                                      <div>{String.fromCharCode(97 + index)})</div>
                                      <Grid container sx={{ marginTop: "2%" }}>
                                        <Grid item xs={1.5} md={1.5} />
                                        <Grid item xs={5.2} md={7.3}>
                                          <Typography
                                            variant="h2"
                                            sx={{
                                              fontWeight: 400,
                                              fontStyle: "regular",
                                              color: "#000000",
                                              fontSize: "16px",
                                              lineHeight: "150%",
                                              // paddingBottom: type === "text" ? "0px" : "20px",
                                            }}
                                          >{subQuestion.submqtext}</Typography></Grid></Grid>
                                      <div style={{ marginLeft: '5px', marginRight: '5px' }}>{" "}</div>
                                    </div>
                                  </CardContent>
                                </Card>

                                {subQuestion.children.map((child, childIndex) => {
                                  const romanNumeral = romanNumbers[childIndex];
                                  return (
                                    <React.Fragment key={childIndex}>
                                     
                                      {child.submqqtype === "multichoice" ? (
                                        <>
                                          <Card
                                            sx={{
                                              // paddingBottom: "25px",
                                              width: "100%",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <CardContent> {romanNumeral})
                                              <MultiChoiceNew
                                                question={child}
                                                selectedAnswer={selectedAnswer}
                                                setSelectedAnswer={setSelectedAnswer}
                                                show={false}
                                              />
                                            </CardContent>
                                          </Card>

                                          <FeedbackQuestion count={count} page={page} quizAttemptDetail={child} />
                                        </>
                                      ) : null}
                                      {child.submqqtype === "truefalse" ? (
                                        <>
                                          <Card
                                            sx={{
                                              // paddingBottom: "25px",
                                              width: "100%",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <CardContent> {romanNumeral})
                                              <TrueFalseNew
                                                question={child}
                                                selectedAnswer={selectedAnswer}
                                                setSelectedAnswer={setSelectedAnswer}
                                                show={false}
                                              />{" "}
                                            </CardContent>
                                          </Card>
                                          <FeedbackQuestion count={count} page={page} quizAttemptDetail={child} />
                                        </>
                                      ) : null}
                                      {child.submqqtype === "match" ? (
                                        <>
                                          <Card
                                            sx={{
                                              // paddingBottom: "25px",
                                              width: "100%",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <CardContent> {romanNumeral})
                                              <MatchNew question={child} count={count} show={false} />
                                            </CardContent>
                                          </Card>
                                          <FeedbackQuestion count={count} page={page} quizAttemptDetail={child} />
                                        </>
                                      ) : null}
                                      {child.submqqtype === "shortanswer" ? (
                                        <>
                                          <Card
                                            sx={{
                                              // paddingBottom: "25px",
                                              width: "100%",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <CardContent> {romanNumeral})
                                              <ShortAnswerNew question={child} show={false} />{" "}
                                            </CardContent>
                                          </Card>
                                          <FeedbackQuestion count={count} page={page} quizAttemptDetail={child} />
                                        </>
                                      ) : null}
                                      {child.submqqtype === "essay" ? (
                                        <>
                                          <Card
                                            sx={{
                                              // paddingBottom: "25px",
                                              width: "100%",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <CardContent> {romanNumeral})
                                              <EssayNew question={child} show={false} />{" "}
                                            </CardContent>
                                          </Card>
                                          <FeedbackQuestion count={count} page={page} quizAttemptDetail={child} />
                                        </>
                                      ) : null}
                                    </React.Fragment>
                                  );
                                })}
                              </>
                            ) : (
                              ""
                            )}
                            {subQuestion.submqqtype === "multichoice" ? (
                              <>
                                <Card
                                  sx={{
                                    // paddingBottom: "25px",
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span>{page}</span>
                                    <span>{String.fromCharCode(97 + index)})</span>
                                    <MultiChoiceNew
                                      question={subQuestion}
                                      selectedAnswer={selectedAnswer}
                                      setSelectedAnswer={setSelectedAnswer}
                                      show={false}
                                    /></div>
                                  </CardContent>
                                </Card>

                                <FeedbackQuestion count={count} page={page} quizAttemptDetail={subQuestion} />
                              </>
                            ) : (
                              ""
                            )}
                            {subQuestion.submqqtype === "truefalse" ? (
                              <>
                                <Card
                                  sx={{
                                    // paddingBottom: "25px",
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span>{page}</span>
                                    <span>{String.fromCharCode(97 + index)})</span>
                                    <TrueFalseNew
                                      question={subQuestion}
                                      selectedAnswer={selectedAnswer}
                                      setSelectedAnswer={setSelectedAnswer}
                                      show={false}
                                    /></div>
                                  </CardContent>
                                </Card>
                                <FeedbackQuestion count={count} page={page} quizAttemptDetail={subQuestion} />
                              </>
                            ) : (
                              ""
                            )}
                            {subQuestion.submqqtype === "match" ? (
                              <>
                                <Card
                                  sx={{
                                    // paddingBottom: "25px",
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span>{page}</span>
                                    <span>{String.fromCharCode(97 + index)})</span>
                                    <MatchNew question={subQuestion} count={count} show={false} /></div>
                                  </CardContent>
                                </Card>
                                <FeedbackQuestion count={count} page={page} quizAttemptDetail={subQuestion} />
                              </>
                            ) : (
                              ""
                            )}
                            {subQuestion.submqqtype === "shortanswer" ? (
                              <>
                                <Card
                                  sx={{
                                    // paddingBottom: "25px",
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                    <span>{page}</span>
                                    <span>{String.fromCharCode(97 + index)})</span>
                                    <ShortAnswerNew question={subQuestion} show={false} />{" "}</div>
                                  </CardContent>
                                </Card>
                                <FeedbackQuestion count={count} page={page} quizAttemptDetail={subQuestion} />
                              </>
                            ) : (
                              ""
                            )}
                            {subQuestion.submqqtype === "essay" ? (
                              <>
                                <Card
                                  sx={{
                                    // paddingBottom: "25px",
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <CardContent>
                                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                      <span>{page}</span>
                                      <span>{String.fromCharCode(97 + index)})</span>
                                      <EssayNew question={subQuestion} show={false} />
                                    </div>
                                  </CardContent>
                                </Card>
                                <FeedbackQuestion count={count} page={page} quizAttemptDetail={subQuestion} />
                              </>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {/* </CardContent>
              </Card> */}
                </Grid>{" "}
              </>
            )}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Typography
                sx={{
                  fontWeight: 700,
                  fontStyle: "regular",
                  color: "#634699",
                  fontSize: 16,
                  lineHeight: "140%",
                  padding: "5px",
                  marginRight: "20px",
                }}
              >
                Questions
              </Typography>
              <Pagination
                variant="outlined"
                shape="rounded"
                className={classes.pagination}
                count={quizAttemptDetail[0].questionlist.length}
                page={page}
                onChange={HANDLECHANGE}
                hideNextButton
                hidePrevButton
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container sx={{ marginTop: "16px" }}>
                <Grid item xs={4} md={8} />
                <Grid
                  item
                  xs={4}
                  md={2}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={HANDLEPREVCLICK}
                    disabled={page === 1}
                    sx={{
                      background: "#634699",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      textTransform: "none",
                      marginLeft: "10px",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#634699",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {" "}
                    <ArrowLeftIcon />
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        fontSize: "14px",
                        lineHeight: "140%",
                      }}
                    >
                      Prev
                    </Typography>
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={4}
                  md={2}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={HANDLENEXTCLICK}
                    // disabled={page === quizAttemptDetail[0].questionlist.length}
                    sx={{
                      background: "#634699",
                      color: "#FFFFFF",
                      borderRadius: "5px",
                      textTransform: "none",
                      marginLeft: "10px",
                      boxShadow: "none",
                      "&:hover": {
                        backgroundColor: "#634699",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontStyle: "regular",
                        fontSize: "14px",
                        lineHeight: "140%",
                        textAlign: "center",
                      }}
                    >
                      Next
                    </Typography>
                    <ArrowRightIcon />
                  </Button>
                  <Dialog
                    open={openDialog}
                    PaperProps={{
                      style: {
                        background: "#FFFFFF",
                        boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
                        borderRadius: "5px",
                        padding: "5%",
                        width: "1000px",
                      },
                    }}
                  >
                    <DialogContent>
                      <Grid container item display="flex" alignItems="center" justifyContent="center">
                        <Grid item xs={4} />
                        <Grid item xs={3.7}>
                          <img src={flag} alt="success" width="120.06px" height="130.08px" />
                        </Grid>
                        <Grid item xs={4} />
                      </Grid>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 700,
                          fontSize: "40px",
                          lineHeight: "60px",
                          textAlign: "center",
                          // marginBottom:"15px"
                        }}
                      >
                        FINISH!
                      </Typography>
                      <Typography
                        sx={{
                          color: "#634699",
                          fontWeight: 400,
                          fontSize: "18px",
                          lineHeight: "36px",
                          textAlign: "center",
                          // marginBottom:"15px"
                        }}
                      >
                        It seems you have Reviewed all the Questions
                      </Typography>
                    </DialogContent>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => setOpenDialog(false)}
                        sx={{
                          background: "#FFFFFF",
                          color: "#634699",
                          marginBottom: "15px",
                          borderRadius: "5px",
                          textTransform: "none",
                          border: "1px solid #634699",
                          fontWeight: 400,
                          fontSize: "14px",
                          boxShadow: "none",
                          padding: "5px 35px 5px 35px",
                          "&:hover": {
                            backgroundColor: "#FFFFFF",
                            color: "#634699",
                            boxShadow: "none",
                          },
                        }}
                      >
                        Go Back
                      </Button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        onClick={() => history.push("/login/mocktestform")}
                        sx={{
                          background: "#634699",
                          color: "#FFFFFF",
                          borderRadius: "5px",
                          textTransform: "none",
                          // marginLeft: "10px",
                          boxShadow: "none",
                          padding: "5px 35px 5px 35px",
                          fontWeight: 400,
                          fontSize: "14px",
                          "&:hover": { backgroundColor: "#634699", boxShadow: "none" },
                        }}
                      >
                         Try again 
                      </Button>
                    </div>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>
          </Fragment>
        </div>
      </Fragment>
    {/* </Grid> */}
    </div></Grid></div>
  ) : (
    ""
  );
};

export default OfflineQuestionFeedbacknew;
