// import { Button, Grid } from '@mui/material';
import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Box,
  Button,
  Typography,
  Divider,
  Card,
  CardContent,
  LinearProgress,
  IconButton,
  Tooltip,
  Zoom,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { fetchFeedbackList } from "../../../webservice/students/actions/FeedbackAction";
import { fetchUserId } from "../../../webservice/students/actions/AuthAction";
import {
  Feedbackiconbtn,
  GradeIcon,
  Scheduleiconbtn,
  Scheduleimage,
  TestPackageListIcon,
} from "../../../assets/icons";
import { fetchQuizDetails } from "../../../webservice/students/actions/MyTestPackageAction";
const USE_STYLES = makeStyles({
  customTooltip: {
    backgroundColor: '#634699',
    color: 'white',
    '& .MuiTooltip-arrow': {
      color: '#634699',
    },
  },
  icon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    margin: "12px",
  },
  myTestPackageContainer: {
    overflow: "hidden",
    overflowY: "auto",
    paddingBottom: "100px",
    marginTop: "5px",
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderStyle: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      fontSize: "2rem",
    },
    "& .MuiInputBase-formControl": {
      padding: "8px",
      textAlign: "center",
      color: "#634699",
      backgroundColor: "#fff",
    },
  },
  gradeIcon: {
    "& .MuiSvgIcon-root": {
      fill: "#E2DDEC",
    },
  },
});

const Results = () => {
  let history = useHistory();
  const CLASSES = USE_STYLES();
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  const { allfeedbacks } = useSelector((state) => state.feedbackState);

  useEffect(() => {
    dispatch(fetchUserId());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchFeedbackList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);

  // Flatten and sort feedbacks by timefinish in descending order
  const sortedFeedbacks = allfeedbacks
    ?.flat()
    .sort((a, b) => b.timefinish - a.timefinish);


const feedbacksByDate = {};
if (sortedFeedbacks) {
   sortedFeedbacks?.forEach((subItems) => {
      const date = new Date(subItems.timefinish * 1000).toLocaleDateString();
      if (feedbacksByDate[date]) {
        feedbacksByDate[date].push(subItems);
      } else {
        feedbacksByDate[date] = [subItems];
      }
  });
}
// allfeedbacks.forEach((feedback) => {
//   feedback.forEach((subItems) => {
//     const date = new Date(subItems.timefinish * 1000).toLocaleDateString();
//     if (feedbacksByDate[date]) {
//       feedbacksByDate[date].push(subItems);
//     } else {
//       feedbacksByDate[date] = [subItems];
//     }
//   });
// });
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={8} md={8}>
          <Typography
            sx={{
              color: "#634699",
              fontWeight: 700,
              fontSize: "2rem",
              lineHeight: "56px",
            }}
          >
            Test Results
          </Typography>
        </Grid>{" "}
      </Grid>
      {Object.keys(feedbacksByDate).length === 0 ? (
  <div>
    <Typography variant="body1" sx={{
                     fontWeight: "bold",
                     fontSize: "16px",
                     color: "#634699",
                     textAlign: "center",
                     margin:"50px"
                   }}>No Test Results available at the moment.</Typography>
  </div>
) : (
      Object.keys(feedbacksByDate).map((date, index) => (
  <Fragment key={index}>
   <div style={{ display: "flex", alignItems: "center" }}>
   <Divider sx={{ flexGrow: 1, marginLeft: "10px",backgroundColor: "#634699", }} orientation="horizontal" />
  <Typography sx={{ fontWeight: 700, fontSize: "14px", color: "#634699", marginBottom: "5px" }}>
    {date}
  </Typography>
  <Divider sx={{ flexGrow: 1, marginLeft: "10px", marginRight: "10px",backgroundColor: "#634699", }} orientation="horizontal" />
</div>
    {feedbacksByDate[date].map((subItems, sIndex) => {
      const now = new Date();
      const timefinishDate = new Date(subItems.timefinish * 1000);
      const diffTime = now - timefinishDate;
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const daysAgo = `${diffDays} days ago`;
     return (
       <div key={sIndex}>
         <Card sx={{ marginBottom: "5px" ,padding:"8px"}} key={index}>
           {/* <CardContent> */}
             <Grid container>
               <Grid item xs={1}>
                 <TestPackageListIcon className={CLASSES.icon} />
               </Grid>
               <Grid item xs={7}>
                 <Typography
                   sx={{
                     fontWeight: "bold",
                     fontSize: "14px",
                     color: "#634699",
                     textAlign: "left",
                     padding:"12px 0px"
                   }}
                 >
                   {subItems.quizname}
                   {/* <br /> */}
                   <span
                     style={{
                       fontSize: "14px",
                       color: "#634699",
                       fontWeight: 700,
                     paddingLeft:"10px",
                     textAlign: "left",
                     }}
                   >
                     {/* {subItems.status} */}
                     Completed-{" "}
                     <span
                       style={{
                         fontSize: "14px",
                         color: "#718096",
                         fontWeight: 700,
                         textAlign: "left",
                       }}
                     >
                       {new Date(
                         subItems.timefinish * 1000
                       ).toLocaleDateString()}
                     </span>
                   </span>
                 </Typography>
               </Grid>
               <Grid item xs={4}>
                 <div style={{ display: "flex", marginTop:"10px"}}>
                   
                   <GradeIcon sx={{ fill: "white" }} />{" "}
                   <Typography
                     sx={{
                       fontWeight: 700,
                       fontSize: "30.1px",
                       color: "#634699",
                       // textAlign: "left",
                       lineHeight: "29.42px",
                       paddingRight: "15px",
                     }}
                   >{subItems.strgrade}</Typography>
                   <Tooltip title="Feedback" placement="top"  TransitionComponent={Zoom} classes={{ tooltip: CLASSES.customTooltip }} arrow>
                   <IconButton
                       component={Link}
                       to={`/my-test-packages/${subItems.courseid}/feedback/${subItems.quizid}`}
                     sx={{
                       padding: "0px",
                       color: "#634699",
                       textTransform: "none",
                       boxShadow: "none",
                       marginRight: "15px",
                       "&:hover": {
                         backgroundColor: "#FFFFFF",
                         boxShadow: "none",
                       },
                     }}
                   >
                     {" "}
                     <Feedbackiconbtn sx={{ fontSize: 22 }} />
                   </IconButton></Tooltip>
                   <Tooltip title="Schedule meeting" placement="top"  TransitionComponent={Zoom} classes={{ tooltip: CLASSES.customTooltip }} arrow>
                   <IconButton
                     component={Link}
                     to="/schedule"
                     sx={{
                       padding: "0px",
                       paddingRight: "15px",

                       color: "#634699",
                       textTransform: "none",
                       boxShadow: "none",
                       "&:hover": {
                         backgroundColor: "#FFFFFF",
                         boxShadow: "none",
                       },
                     }}
                   >
                     {" "}
                     <Scheduleimage />
                   </IconButton></Tooltip>
                   <Typography
               sx={{
                 fontWeight: 700,
                 fontSize: "12px",
                 color: "#634699",
                 lineHeight: "29.42px",
                 textAlign: "center",
                 backgroundColor: "#D4D9E0",
                 borderRadius: "5px",
                 padding:"1px 2px"
               }}
             >{daysAgo}
               </Typography></div>
                 <Grid item xs={2}>
                 </Grid>
         </Grid>
             </Grid>
           {/* </CardContent> */}
         </Card>
        
         
       </div>
     );
      
    })}
  </Fragment>
)))}
 
    </div>
  );
};

export default Results;
