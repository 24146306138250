/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class views/students/test/packages/CompletedTests.js
 * This JS file is intendend to create a card component for showing the tests that are completed.
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import React, { useEffect } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { Checkfeedbackwhite, Feedbackiconbtn, GradeIcon,GradeIcon2, Scheduleiconbtn, Schedulewhite, TestPackageListIcon } from "../../../../assets/icons";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuizDetails } from "../../../../webservice/students/actions/MyTestPackageAction";

const USE_STYLES = makeStyles({
  icon: {
    backgroundColor: "#634699",
    padding: "3px",
    borderRadius: "5px",
    width: 26,
    height: 26,
  },
  gradeIcon: {
    
  },
});

const COMPLETEDTESTS = (props) => {
  const classes = USE_STYLES();
  const params = useParams();
  const courseid = params.subject;
  const { quizList } = useSelector((state) => state.mytestpackagestate);
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(fetchQuizDetails());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  return (
    <Grid container>
      <Grid item xs={2} md={1.3}>
        <TestPackageListIcon className={classes.icon} />
      </Grid>
      <Grid item xs={4} md={4.7}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "14px",
            fontStyle: "regular",
            lineHeight: "22px",
            color:  props.module.locked ? "#A9A9A9" : "#634699",
          }}
        >
          {props.module.name}
        </Typography>
      </Grid>
      <Grid item xs={4} md={6}>
        <Grid container display="flex" justifyContent="flex-end">
  <Grid
    item
    xs={3}
    md={2}
    display="flex"
    justifyContent="flex-end"
    sx={{
      "& .MuiSvgIcon-root": {
        fill: props.module.locked ? "#A9A9A9" : "#E2DDEC",
      },
    }}
  >
    {props.module.reviewfinished ? (
      props.module.finalstrgrade ? (
      props.module.locked ? <GradeIcon2 /> : <GradeIcon />
    ) : ""):""}
  </Grid>

  <Grid item xs={2} md={4}>
    <Typography
      sx={{
        fontWeight: 700,
        fontSize: "30px",
        fontStyle: "regular",
        lineHeight: "20px",
        color: props.module.locked ? "#A9A9A9" : "#634699",
      }}
    >
      {props.module.reviewfinished ? (
        props.module.finalstrgrade ? (
          props.module.finalstrgrade
        ) : (
          <span
            style={{
              fontWeight: 700,
              fontSize: "12.5px",
              fontStyle: "regular",
              // lineHeight: "1.375rem",
            lineHeight: "22px",
              color: props.module.locked ? "#A9A9A9" : "#634699",
            }}
          >
            Not Graded
          </span>
        )
      ) : (
        <span
          style={{
            fontWeight: 700,
            fontSize: "12.5px",
            fontStyle: "regular",
            // lineHeight: "1.375rem",
            lineHeight: "22px",
            color: props.module.locked ? "#A9A9A9" : "#634699",
          }}
        >
          Not Graded
        </span>
      )}
    </Typography>
  </Grid>
</Grid>

      </Grid>

      <Grid item xs={12} display="flex" justifyContent="flex-end">
      {quizList && quizList.quizzes && quizList.quizzes.length
        ? quizList.quizzes.map((quiz,index) => {
            return quiz.coursemodule === props.module.id ? (
              
              <Button key={index}
              variant="contained"
              component={Link}
              disabled={props.module.locked}
              to={`${courseid}/feedback/${quiz.id}`}
              sx={{fontSize:"12px",
                textTransform: "none",
                backgroundColor: props.module.locked ? "#A9A9A9" : "#634699",
                "&:hover": {
                  backgroundColor: props.module.locked ? "#A9A9A9" : "#634699"
                },
                marginRight: "10px",
              }}
            >
              <Checkfeedbackwhite sx={{ fontSize: 22, }} />
                          &nbsp;Check Feedback
            </Button>
              
            ) : null;
          })
        : null}   
        <Button
          component={Link}
          to="/schedule"
          variant="contained"
          disabled={props.module.locked}
          sx={{fontSize:"12px",
            textTransform: "none",
            backgroundColor:  props.module.locked ? "#A9A9A9" : "#634699",
            "&:hover": {
              backgroundColor:  props.module.locked ? "#A9A9A9" : "#634699",
            },
          }}
        >
            <Scheduleiconbtn sx={{ fontSize: 22 }} />
                  &nbsp;Schedule meeting
        </Button>
      </Grid>
    </Grid>
  );
};

export default COMPLETEDTESTS;
