/**All right reserved under Cenablecare Edutech © 2022 */

/**
 * @class TestExplore.js
 * This JS file is for showing the list of test packages to explore
 *
 * @author tincy.thomas@centelon.com
 * @since 07 Feb 2022
 */

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  List,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { AddToCartIcon, ExpandIcon, TestPackageListIcon } from "../../../../assets/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  carteditemsaction,
  fetchCourseContents,
  fetchCourseList,
} from "../../../../webservice/students/actions/TestPackageListAction";
import { fetchUserId } from "../../../../webservice/students/actions/AuthAction";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { Fragment } from "react";
import mailicon from "../../../../assets/images/cartaddedimage.png";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import Image from "../../../../assets/images/collapse-testicon.png";
import Pic from "../../../../assets/images/collapse-sessionicon.png";

const SEARCH = styled("div")(({ theme }) => ({
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: "0.5px solid #E2E8F0",
  marginLeft: 0,
  borderRadius: "5px",
  width: "98%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "98%",
  },
}));

const SEARCH_ICON_WRAPPER = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 0),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "15px",
  justifyContent: "center",
  color: "gray",
  marginTop: "5px",
  marginLeft: "10px",
}));
const STYLED_INPUT_BASE = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(2)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const USE_STYLES = makeStyles({
  test: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px",
  },
  session: {
    backgroundImage: `url(${Pic})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "60px",
  },
  carticon: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "rgb(99 70 153 / 40%)",
    },
  },
  icons: {
    backgroundColor: "#634699",
    padding: "4px",
    borderRadius: "5px",
    marginTop: "10px",
  },
  icon: {
    backgroundColor: "#634699",
    padding: "10px",
    borderRadius: "5px",
    height: 65,
    width: 65,
  },
  formcontrol: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputLabel-formControl": {
      color: "#634699",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      fill: "#634699",
      // fontSize: "2rem",
      marginRight: "15px",
    },
    "& .MuiInputBase-formControl": {
      textAlign: "center",
      color: "#634699",
      background: "#ffffff",
      boxShadow: "0px 3.5px 5.5px rgb(0 0 0 / 2%)",
      borderRadius: "5px",
      height: "40px",
    },
  },
  select: {
    "&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
      // paddingRight: '10px',
      overflow: "inherit",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      marginRight: "-24px",
    },
    "&:after": {
      borderColor: "transparent",
    },
  },
  popover: {
    "& .MuiPopover-paper": {
      marginTop: "127px",
      marginLeft: "16px",
    },
  },
});
const TESTEXPLORE = () => {
  const HISTORY = useHistory();
  const classes = USE_STYLES();
  const dispatch = useDispatch();
  const { userid } = useSelector((state) => state.authState);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const { gradeSubjectList } = useSelector((state) => state.testPackageListReducer);

  const [open, setOpen] = useState(false);
  const [dialogFullname, setDialogFullname] = useState("");
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    dispatch(fetchUserId());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (userid) dispatch(fetchCourseList(userid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userid]);
  const { courseList } = useSelector((state) => state.testPackageListReducer);
  const filteredArray = courseList?.slice((courseList?.length - courseList?.length, courseList?.length - 3));
  const { coursecontents } = useSelector((state) => state.testPackageListReducer);
  
  const filteredContents = coursecontents?.filter((x) => x.section !== 0 && x.modules && x.modules.length !== 0);
  const [lastAddedItem, setLastAddedItem] = useState(null);
  const [cartOpen, setCartOpen] = useState(false);
  useEffect(() => {
    if (dialogFullname.id) {
      dispatch(fetchCourseContents(dialogFullname.id, userid));
    }
  }, [dispatch, dialogFullname.id, userid]);

  const handleGradeChange = (e) => {
    setSelectedGrade(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const handleClickOpen = (fullname) => {
    setDialogFullname(fullname);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSeeMore = () => {
    setShowAll(!showAll);
  };

  const getFilteredCourseList = () => {
    let list = courseList.filter((val) => {
      if (searchTerm === "" && selectedGrade === "" && selectedSubject === "") {
        return val;
      } else if (
        val.displayname.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedGrade
          ? val.customfields[0].name === "Grades" && val.customfields[0].valueraw === selectedGrade.toString()
          : true) &&
        (selectedSubject
          ? val.customfields[1].name === "Subjects" && val.customfields[1].valueraw === selectedSubject.toString()
          : true)
      ) {
        return val;
      }
    });
    if (list.length) return list;
    else return [{ format: "no-topics" }];
  };

  const renderCourseCards = () => {
    const filteredItems = showAll ? getFilteredCourseList() : getFilteredCourseList().slice(0, 4);

    return filteredItems.map((data, index) => {
      let quizCount = 0; // Reset inside map
      let assignCount = 0; // Reset inside map

      return data.format === "topics" && data.categoryid === 2 && data.visible === 1 ? (
        <Grid item xs={12} md={4} key={data.id}>
           <Card
                      sx={{
                        wordWrap: "break-word",
                        //anusha
                        minHeight: "235px" ,
                        // other styles
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={1} direction="column" textAlign="center">
                          <Grid item>
                            <TestPackageListIcon className={classes.icon} />
                          </Grid>
                          <Grid item>
                            <Grid container direction="column" textAlign="center">
                              <Grid item>
                                <Typography
                                  sx={{
                                    color: "#634699",
                                    fontWeight: 700,
                                    //anusha
                                    fontSize: "13px",
                                    lineHeight: "140%",
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {data.fullname}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Button
                              // onClick={() => {
                              //   HISTORY.push(`/test-packages/${data.id}`);
                              // }}
                              onClick={() => handleClickOpen(data)}
                              sx={{
                                background: "#FFFFFF",
                                color: "#634699",
                                borderRadius: "5px",
                                textTransform: "none",
                                padding: "6px 25px",
                                border: "1px solid #634699",
                                boxShadow: "none",
                                //anusha
                                marginTop: "15px",
                                "&:hover": {
                                  backgroundColor: "#FFFFFF",
                                  color: "#634699",
                                  boxShadow: "none",
                                },
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 400,
                                  fontStyle: "regular",
                                  fontSize: "14px",
                                  lineHeight: "140%",
                                }}
                              >
                                View Details
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
        </Grid>
      ) : data.format === "no-topics" ? (
        <Grid item xs={12} key={data.format}>
          <Typography>No Result Found..!</Typography>
        </Grid>
      ) : null;
    });
  };
  const handleAddToCart = (itemList) => {
    dispatch(carteditemsaction(itemList));
    setLastAddedItem(itemList);
    setCartOpen(true);
  };
  const handleCartClose = () => {
    setCartOpen(false);
    setLastAddedItem(null);
  };
  const [hover, sethover] = useState(false);
  let quizCount = 0;
  let assignCount = 0;
  const [showSyllabus, setShowSyllabus] = useState(false);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={5} rowSpacing={2} columnSpacing={5}>
          <Grid item xs={12} md={12}>
            <Grid container display="flex">
              <Grid item xs={12} md={6}>
                <div>
                <SEARCH>
                  <SEARCH_ICON_WRAPPER>
                    <SearchIcon sx={{ fontSize: 18, margin: "5px" }} />
                  </SEARCH_ICON_WRAPPER>
                  <STYLED_INPUT_BASE
                    type="text"
                    placeholder="Search Courses"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                </SEARCH>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Grid item xs={3} md={5}>
                    <FormControl fullWidth className={classes.formcontrol} variant="outlined">
                      <Select
                        sx={{ overflow: "inherit" }}
                        id="grouped-select"
                        label="Grouping"
                        displayEmpty
                        onChange={handleGradeChange}
                        className={classes.select}
                        defaultValue={""}
                        IconComponent={() => <ExpandIcon sx={{ fontSize: 12, transform: "rotate(90deg)" }} />}
                      >
                        <MenuItem value="">
                          Grade
                        </MenuItem>
                        {gradeSubjectList && gradeSubjectList?.grades?.length
                          ? gradeSubjectList?.grades?.map((grade, index) => (
                              <MenuItem key={index} value={grade.id}
                              sx={{
                                color: "#634699",
                                backgroundColor: "white",
                                "&.Mui-selected": {
                                  background: "#634699",
                                  color: "white",
                                },
                              }}>
                                {grade.gradename}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3} md={5}>
                    <FormControl fullWidth className={classes.formcontrol} variant="outlined">
                      <Select
                        id="grouped-select"
                        label="Grouping"
                        displayEmpty
                        onChange={handleSubjectChange}
                        className={classes.select}
                        defaultValue={""}
                        IconComponent={() => <ExpandIcon sx={{ fontSize: 12, transform: "rotate(90deg)" }} />}
                      >
                        <MenuItem value=""
                        sx={{
                            color: "#634699",
                            backgroundColor: "white",
                          }}>
                          Subject
                        </MenuItem>
                        {selectedGrade
                          ? gradeSubjectList && gradeSubjectList?.mapping?.length
                            ? gradeSubjectList?.mapping?.map((grade, index) => {
                                return (
                                  grade.grade === selectedGrade &&
                                  (grade?.gradesubjects?.length
                                    ? grade?.gradesubjects?.map((subject, index) => (
                                        <MenuItem key={index} value={subject.subjectid}
                                        sx={{
                                          color: "#634699",
                                          backgroundColor: "white",
                                        }}>
                                          {subject.subjectname}
                                        </MenuItem>
                                      ))
                                    : null)
                                );
                              })
                            : null
                          : gradeSubjectList && gradeSubjectList?.subjects?.length
                          ? gradeSubjectList?.subjects?.map((subject, index) => (
                              <MenuItem key={index} value={subject.id}
                              sx={{
                                color: "#634699",
                                backgroundColor: "white",
                              }}>
                                {subject.subject}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {courseList && courseList.length
            ? renderCourseCards()
            : null}
          {courseList && courseList.length > 3 ? (
            <Grid item xs={12} textAlign="center">
              {/* <Button onClick={handleSeeMore}></Button> */}
              <Link
                 onClick={handleSeeMore}
                  variant="contained"
                  sx={{
                    cursor:"pointer",
                    textDecoration:"none",
                    backgroundColor: "transparent",
                    textTransform: "none",
                    fontSize: "12px",
                    color: "#634699",
                    fontWeight: 700,
                    // "&:hover": { backgroundColor: "#634699", color: "white" },
                  }}
                >
                 {showAll ? "Show Less" : "See More"}
                </Link>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Dialog
        PaperProps={{
          style: {
            background: "#FFFFFF",
            boxShadow: "0px 1.91055px 3.00229px rgba(0, 0, 0, 0.02)",
            borderRadius: "5px",
            padding: "1%",
            // width: "1000px",
            width: "579px",
          },
        }}
        open={open}
        // open={snackbar.open}
        sx={{
          backgroundColor: "rgba(99, 70, 153,  60%)",
        }}
      >
        <Fragment key={dialogFullname.id}>
          <Grid item xs={12} key={dialogFullname.id}>
            <Card sx={{ marginBottom: "10px" }}>
              <CardContent>
                <Grid container>
                  <Grid item xs={2} md={1}>
                    <TestPackageListIcon className={classes.icons} />
                  </Grid>
                  {/* <Grid item xs={4} md={4}> */}
                  <Grid item xs={7} md={7}>
                    <Typography
                      sx={{
                        color: "#634699",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "38.4px",
                      }}
                    >
                      {dialogFullname.displayname}
                      <span
                        style={{
                          color: "#718096",
                          fontWeight: 400,
                          fontSize: "14px",
                          lineHeight: "38.4px",
                          marginLeft: "20px",
                        }}
                      >
                        {dialogFullname.customfields && dialogFullname.customfields.length > 0
                          ? dialogFullname.customfields[0].name === "Grades" &&
                            dialogFullname.customfields[0].value !== null &&
                            !isNaN(dialogFullname.customfields[0].value)
                            ? dialogFullname.customfields[0].value +
                              (parseInt(dialogFullname.customfields[0].value) % 100 === 11 ||
                              parseInt(dialogFullname.customfields[0].value) % 100 === 12 ||
                              parseInt(dialogFullname.customfields[0].value) % 100 === 13
                                ? "th"
                                : parseInt(dialogFullname.customfields[0].value) % 10 === 1
                                ? "st"
                                : parseInt(dialogFullname.customfields[0].value) % 10 === 2
                                ? "nd"
                                : parseInt(dialogFullname.customfields[0].value) % 10 === 3
                                ? "rd"
                                : "th")
                            : dialogFullname.customfields[0].value !== null
                            ? dialogFullname.customfields[0].value
                            : ""
                          : ""}
                      </span>

                      <br />
                      <span
                        style={{
                          color: "#C4C4C4",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "16.8px",
                        }}
                      >
                        {/* {dialogFullname.summary} */}
                        {dialogFullname.summary && dialogFullname.summary.replace(/<[^>]+>/g, "")}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={1.5}>
                    {dialogFullname?.paypalenrollments &&
                      dialogFullname.paypalenrollments.map((pricedata, index) => (
                        <Typography
                          key={index}
                          sx={{
                            color: "#634699",
                            fontWeight: 700,
                            fontSize: "16px",
                            lineHeight: "38.4px",
                          }}
                        >
                          {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: pricedata?.currency,
                            maximumSignificantDigits: 3,
                          }).format(pricedata?.cost)}
                        </Typography>
                      ))}
                  </Grid>

                  {/* <Grid item xs={2} md={1}></Grid> */}
                  <Grid item xs={2} md={1.5}>
                    <IconButton className={classes.carticon} onClick={() => handleAddToCart(dialogFullname)}>
                      <AddToCartIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                    {cartOpen && lastAddedItem === dialogFullname && (
                      // <CartPopup cartItems={[data]}  />
                      <Dialog
                        open={cartOpen}
                        onClose={handleCartClose}
                        PaperProps={{
                          style: {
                            maxHeight: "none",
                          },
                        }}
                        BackdropProps={{ invisible: true }}
                      >
                        <Grid container item xs={12}>
                          <Card className={classes.testContainer} sx={{ minWidth: 600 }}>
                            <Grid display="flex" alignItems="center" justifyContent="center">
                              <img src={mailicon} alt="mailicon" width="100px" height="100px" />
                            </Grid>
                            <br />

                            <Typography
                              variant="h1"
                              sx={{
                                color: "#634699",
                                fontWeight: 700,
                                fontSize: "16px",
                                lineHeight: "160%",
                                textAlign: "center",
                              }}
                            >
                              {dialogFullname.displayname}
                              <br />
                              successfully carted
                            </Typography>
                            <Grid display="flex" alignItems="center" justifyContent="center"></Grid>
                          </Card>
                        </Grid>
                      </Dialog>
                    )}
                  </Grid>
                  <Grid item xs={3} md={1}>
                    <HighlightOffSharpIcon
                      sx={{ color: "#634699", fontSize: 32, marginTop: "2px", cursor: "pointer" }}
                      onClick={handleClose}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {coursecontents && coursecontents.length
                      ? coursecontents.map((content, indexcontent) => {
                          if (content && content.modules && content.modules.length) {
                            let quizlist = content.modules.filter((e) => e.modname === "quiz");
                            if (quizlist.length) {
                              quizCount = quizCount + quizlist.length;
                            }
                            let assignlist = content.modules.filter((e) => e.modname === "assign");
                            if (assignlist.length) {
                              assignCount = assignCount + assignlist.length;
                            }
                          }
                          return coursecontents.length - 1 === indexcontent ? (
                            // <Collapse in={expanded === index} key={index}>
                            <Card key={indexcontent}>
                              {/* <CardContent> */}
                              <Grid item xs={12}>
                                <Grid container>
                                  <Grid item md={1}></Grid>
                                  <Grid item md={5} xs={12} className={classes.session}>
                                    <span
                                      style={{
                                        color: "#634699",
                                        fontWeight: 700,
                                        fontSize: "106.667px",
                                        lineHeight: "160px",
                                      }}
                                    >
                                      {filteredContents.length}
                                    </span>
                                    <Typography
                                      sx={{
                                        color: "#634699",
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        lineHeight: "150%",
                                      }}
                                    >
                                      Sessions
                                    </Typography>
                                  </Grid>
                                  <Grid item md={6} xs={12} className={classes.test}>
                                    <span
                                      style={{
                                        color: "#634699",
                                        fontWeight: 700,
                                        fontSize: "106.667px",
                                        lineHeight: "160px",
                                      }}
                                    >
                                      {quizCount}
                                    </span>
                                    <Typography
                                      sx={{
                                        color: "#634699",
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        lineHeight: "150%",
                                      }}
                                    >
                                      Tests
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid item xs={8} sx={{ marginTop: "22px" }}>
                                    {showSyllabus && (
                                      <div>
                                        {filteredContents.map((item) => (
                                          <Grid>
                                            <List key={item.id}>
                                              <Typography
                                                sx={{
                                                  color: "#634699",
                                                  fontWeight: 400,
                                                  fontSize: "16px",
                                                  lineHeight: "140%",
                                                  marginLeft: "50px",
                                                }}
                                              >
                                                {item.name}
                                              </Typography>
                                            </List>
                                          </Grid>
                                        ))}
                                      </div>
                                    )}
                                  </Grid>
                                  <Grid item xs={4}>
                                    {/* <Grid item xs={2.5}> */}
                                    <Grid container direction="column">
                                      <Grid item>
                                        <Typography
                                          sx={{
                                            color: "#634699",
                                            fontWeight: 700,
                                            fontSize: "1.25vw",
                                            lineHeight: "150%",
                                            width: "200px",
                                            textAlign: "center",
                                          }}
                                        >
                                          {dialogFullname.verificationstatus}
                                        </Typography>
                                      </Grid>
                                      {dialogFullname.enroluistat === false && filteredContents.length !== 0 ? (
                                        <Grid item></Grid>
                                      ) : (
                                        ""
                                      )}
                                      {dialogFullname.cancustomize === 1 && filteredContents.length !== 0 ? (
                                        <Grid item>
                                          <Button
                                            component={Link}
                                            to={`/test-packages/${dialogFullname.id}`}
                                            onMouseOver={() => sethover(true)}
                                            onMouseOut={() => sethover(false)}
                                            variant="outlined"
                                            // fullWidth
                                            sx={{
                                              marginTop: 1,
                                              marginLeft: "50px",
                                              color: "#fff",
                                              borderColor: "#634699",
                                              textTransform: "none",
                                              backgroundColor: "#634699",
                                              "&:hover": {
                                                backgroundColor: "#7C55C5",
                                                borderColor: "#634699",
                                              },
                                            }}
                                          >
                                            {hover ? "Customise" : "Customise"}
                                          </Button>
                                        </Grid>
                                      ) : (
                                        ""
                                      )}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      textTransform: "none",
                                      background: "rgba(224, 218, 235, 0.74)",
                                      color: "#634699",
                                      boxShadow: "none",
                                      fontWeight: "bold",
                                      padding: "8px",
                                      marginTop: "20px",
                                      borderRadius: "4px",
                                      "&:hover": { background: "rgba(224, 218, 235, 0.74)", boxShadow: "none" },
                                    }}
                                    fullWidth
                                    onClick={() => setShowSyllabus(!showSyllabus)}
                                  >
                                    {showSyllabus ? "Show Less" : "Show Test Syllabus"}
                                  </Button>
                                </>
                              </Grid>
                              {/* </CardContent> */}
                            </Card>
                          ) : // </Collapse>
                          null;
                        })
                      : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Fragment>
      </Dialog>
    </Grid>
  );
};

export default TESTEXPLORE;
